import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

export class Consultant extends InstanceAbstract implements InstanceInterface {

	/**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /** ====== Class Properties ====== */

    /**
     * @var number
     */
    private id: number = 0;

    /**
     * @var string
     */
    private first_name: string = '';

    /**
     * @var string
     */
    private last_name: string = '';

    /** ====== Accessors and Mutators ====== */

    /**
     * @return number
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param number
     * @return Consultant
     */
    public setId(id: number): Consultant {
        this.id = id;
        return this;
    }

    /**
     * @return string
     */
    public getFirstName(): string {
        return this.first_name;
    }

    /**
     * @param string title_en
     * @return Consultant
     */
    public setFirstName(first_name: string): Consultant {
        this.first_name = first_name;
        return this;
    }

    /**
     * @return string
     */
    public getLastName(): string {
        return this.last_name;
    }

    /**
     * @param string title_en
     * @return Consultant
     */
    public setLastName(last_name: string): Consultant {
        this.last_name = last_name;
        return this;
    }
}