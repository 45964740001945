import { useGLTF } from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import useTextures from '../../../hooks/useTextures';

type GLTFResult = GLTF & {
  nodes: {
    außen: THREE.Mesh;
    knopf_außen_links: THREE.Mesh;
    knopf_außen_rechts: THREE.Mesh;
    knopf_innen: THREE.Mesh;
    kragen_außen: THREE.Mesh;
    kragen_innen: THREE.Mesh;
  };
};

const ButtonDown = ({ ...props }: JSX.IntrinsicElements['group']) => {
  const { nodes } = useGLTF('./build/models/button_down.glb') as unknown as GLTFResult;

  const collarTexture = useTextures((store) => store.collarTexture?.texture);
  const innerCollarTexture = useTextures((store) => store.innerCollarTexture?.texture);
  const buttonColor = useTextures((store) => store.buttonTexture?.hex_code);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.außen.geometry} position={[-0.43, 70.08, 3.32]} rotation={[0.63, 1.25, 1.71]}>
        <meshStandardMaterial map={collarTexture} />
      </mesh>

      <mesh geometry={nodes.knopf_außen_links.geometry} position={[-6.53, 63.91, 6.16]} rotation={[-3, -0.4, -0.02]}>
        <meshStandardMaterial color={buttonColor} />
      </mesh>

      <mesh geometry={nodes.knopf_außen_rechts.geometry} position={[6.85, 63.8, 5.75]} rotation={[-2.53, -0.83, 0.36]}>
        <meshStandardMaterial color={buttonColor} />
      </mesh>

      <mesh geometry={nodes.knopf_innen.geometry} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color={buttonColor} />
      </mesh>

      <mesh geometry={nodes.kragen_außen.geometry} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial map={collarTexture} />
      </mesh>

      <mesh geometry={nodes.kragen_innen.geometry} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial map={innerCollarTexture} />
      </mesh>
    </group>
  );
};

useGLTF.preload('./build/models/button_down.glb');

export default ButtonDown;
