import React from 'react';

import useTextures from '../../../hooks/useTextures';
import CollarIcon from '../../Icons/CollarIcon';
import ConfiguratorOpenButton from './ConfiguratorOpenButton';

const OpenCollarConfiguratorButton = () => {
  const setConfiguratorTab = useTextures((state) => state.setConfiguratorTab);

  const handleClick = () => setConfiguratorTab('collarDesign');

  return (
    <ConfiguratorOpenButton onClick={handleClick}>
      <CollarIcon />
    </ConfiguratorOpenButton>
  );
};

export default OpenCollarConfiguratorButton;
