import { useViewportSize } from '@mantine/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useTextures from '../../hooks/useTextures';
import { buttonsListProps } from '../../lists/buttonList';
import { styled } from '../../styles/stitches.config';

const Container = styled('div', {
  center: '',
  flexDirection: 'column',
  width: '115px',
  marginBottom: '12px',
  cursor: 'pointer',
});

const Highlight = styled('div', {
  transition: 'border-color 250ms',
  borderRadius: '50%',
  padding: 3,

  border: '3px solid',
  borderColor: 'transparent',

  variants: {
    active: {
      true: {
        borderColor: '#0873E9',
      },
    },
  },
});

const ColorDisplay = styled('div', {
  width: 90,
  height: 90,
  borderRadius: '50%',
  boxShadow: '1px 1px 3px 1px rgba(0,0,0,0.2)',


  '@tablet': {
    width: 80,
    height: 80,
  },
});

type ChangeButtonColorProps = buttonsListProps;

const ChangeButtonColor = ({ hex_code, id, title_en, title_de }: ChangeButtonColorProps) => {
  const { activeButtonColorId, setButtonTexture, setConfiguratorTab, setShareParams } = useTextures((state) => ({
    activeButtonColorId: state.buttonTexture?.id,
    setButtonTexture: state.setButtonTexture,
    setShareParams: state.setShareParams,
    setConfiguratorTab: state.setConfiguratorTab,
  }));

  const { t, i18n } = useTranslation();

  const { width } = useViewportSize();

  const isActive = activeButtonColorId === id;

  const handleClick = async () => {
    if (!isActive) {
      await setButtonTexture({ hex_code, id, title_en, title_de });
      setShareParams();
      if (width <= 768) setConfiguratorTab('closed');
    }
  };

  const setButtonLabel = (title_en: string, title_de: string) => {
    if(title_en == 'FitToCloth') {
      return t('FitToCloth');
    } else {
     return i18n.language === 'en' ? t(title_en) : t(title_de);
    }
  }
  

  return (
    <Container onClick={handleClick}>
      <Highlight active={isActive}>
        <ColorDisplay css={{ backgroundColor: hex_code }} />
      </Highlight>

      <span className='text-center'>{ setButtonLabel(title_en, title_de) }</span>
    </Container>
  );
};

export default ChangeButtonColor;
