import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

export class ButtonColor extends InstanceAbstract implements InstanceInterface {

    /**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /** ====== Class Properties ====== */

    /**
     * @var number
     */
    private id: number = 0;

    /**
     * @var string
     */
    private title_en: string = '';

    /**
     * @var string
     */
    private title_th: string = '';

    /**
     * @var string
     */
    private title_de: string = '';

    /**
     * @var string
     */
    private title_translatable: string = '';

    /**
     * @var string
     */
    private hex_code: string = '';

    /**
     * @var number
     */
    private internal_number: number = 0;

    /**
     * @var number
     */
    private order_color: number = 0;

    /**
     * @var string
     */
    private created_at: string = '';

    /**
     * @var string
     */
    private updated_at: string = '';


    /** ====== Accessors and Mutators ====== */

    /**
     * @return number
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param number
     * @return ButtonColor
     */
    public setId(id: number): ButtonColor {
        this.id = id;
        return this;
    }

    /**
     * @return string
     */
    public getTitleEn(): string {
        return this.title_en;
    }

    /**
     * @param string title_en
     * @return ButtonColor
     */
    public setTitleEn(title_en: string): ButtonColor {
        this.title_en = title_en;
        return this;
    }

    /**
     * @return string
     */
    public getTitleDe(): string {
        return this.title_de;
    }

    /**
     * @param string title_de
     * @return ButtonColor
     */
    public setTitleDe(title_de: string): ButtonColor {
        this.title_de = title_de;
        return this;
    }

    /**
     * @return string
     */
    public getTitleTh(): string {
        return this.title_th;
    }

    /**
     * @param string title_th
     * @return ButtonColor
     */
    public setTitleTh(title_th: string): ButtonColor {
        this.title_th = title_th;
        return this;
    }

    /**
     * @return string
     */
    public getTitleTranslatable(): string {
        return this.title_translatable;
    }

    /**
     * @param string title_translatable
     * @return ButtonColor
     */
    public setTitleTranslatable(title_translatable: string): ButtonColor {
        this.title_translatable = title_translatable;
        return this;
    }

    /**
     * @return string
     */
    public getHexCode(): string {
        return this.hex_code;
    }

    /**
     * @param string hex_code
     * @return self
     */
    public setHexCode(hex_code: string): ButtonColor {
        this.hex_code = hex_code;
        return this;
    }

    /**
     * @return number
     */
    public getInternalNumber(): number {
        return this.internal_number;
    }

    /**
     * @param number internal_number
     * @return ButtonColor
     */
    public setInternalNumber(internal_number: number): ButtonColor {
        this.internal_number = internal_number;
        return this;
    }

    /**
     * @return boolean
     */
    public getOrderColor(): number {
        return this.order_color;
    }

    /**
     * @param boolean embroidery_only
     * @return ButtonColor
     */
    public setOrderColor(order_color: number): ButtonColor {
        this.order_color = order_color;
        return this;
    }

    /**
     * @return string
     */
    public getCreatedAt(): string {
        return this.created_at;
    }

    /**
     * @param string created_at
     * @return ButtonColor
     */
    public setCreatedAt(created_at: string): ButtonColor {
        this.created_at = created_at;
        return this;
    }

    /**
     * @return string
     */
    public getUpdatedAt(): string {
        return this.updated_at;
    }

    /**
     * @param string updated_at
     * @return ButtonColor
     */
    public setUpdatedAt(updated_at: string): ButtonColor {
        this.updated_at = updated_at;
        return this;
    }

    /**
     * Get the translated button title
     * @return string
     */
    public getTranslatedTitle(language: "EN" | "TH" | "DE"): string {
        let title = this.getTitleDe();
        switch (language) {
            case "EN":
                title = this.getTitleEn();
                break;
            case "TH":
                title = this.getTitleTh();
                break;
            case "DE":
            default:
                title = this.getTitleDe();
                break;
        }

        return title;
    }

}