import { useGLTF } from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import useTextures from '../../../hooks/useTextures';

type GLTFResult = GLTF & {
  nodes: {
    außen: THREE.Mesh;
    knopf_oben: THREE.Mesh;
    knopf_unten: THREE.Mesh;
    kragen_außen: THREE.Mesh;
    kragen_innen: THREE.Mesh;
  };
};

const UnderButtonDownLarge = (props: JSX.IntrinsicElements['group']) => {
  const { nodes } = useGLTF('./build/models/under_button_down_large.glb') as unknown as GLTFResult;

  const collarTexture = useTextures((store) => store.collarTexture?.texture);
  const innerCollarTexture = useTextures((store) => store.innerCollarTexture?.texture);
  const buttonColor = useTextures((store) => store.buttonTexture?.hex_code);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.außen.geometry} material={nodes.außen.material} position={[0, 0.64, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial map={collarTexture} />
      </mesh>

      <mesh geometry={nodes.knopf_oben.geometry} material={nodes.knopf_oben.material} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color={buttonColor} />
      </mesh>

      <mesh geometry={nodes.knopf_unten.geometry} material={nodes.knopf_unten.material} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial map={collarTexture} />
      </mesh>

      <mesh geometry={nodes.kragen_außen.geometry} material={nodes.kragen_außen.material} position={[0.01, 0.36, 0.06]}>
        <meshStandardMaterial map={collarTexture} />
      </mesh>

      <mesh geometry={nodes.kragen_innen.geometry} material={nodes.kragen_innen.material} position={[0, 0.34, 0]}>
        <meshStandardMaterial map={innerCollarTexture} />
      </mesh>
    </group>
  );
};

useGLTF.preload('./build/models/under_button_down_large.glb');

export default UnderButtonDownLarge;
