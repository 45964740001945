import { useViewportSize } from '@mantine/hooks'
import React from 'react'

import useTextures from '../../hooks/useTextures'
import { configuratorTab } from '../../hooks/useTextures'
import { texturesListProps } from '../../lists/texturesList'
import { styled } from '../../styles/stitches.config'

interface ChangeTextureProps extends texturesListProps {
  type: configuratorTab
  previewUrl: string
}

const Container = styled('button', {
  width: 110,
  marginBottom: 5,
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Label = styled('span', {
  fontSize: 15,
})

const ImageContainer = styled('div', {
  padding: 2,
  width: 90,
  height: 90,
  borderRadius: '50%',
  border: '3px solid',
  borderColor: 'transparent',

  transition: 'border-color 150ms',

  variants: {
    active: {
      true: { borderColor: '#0873E9' },
    },
  },
})

const Thumbnail = styled('div', {
  borderRadius: '50%',
  backgroundSize: 'cover',
  width: 90,
  height: 90,
})

type selectedIds = {
  bicolorId: number
  cuffsAndCollarTextureId: number
  innerContrastId: number
  mainId: number
}

const getIsSelected = (
  id: number,
  type: configuratorTab,
  { bicolorId, cuffsAndCollarTextureId, innerContrastId, mainId }: selectedIds
) => {
  if (type === 'all') {
    return (
      bicolorId === id &&
      cuffsAndCollarTextureId === id &&
      innerContrastId === id &&
      mainId === id
    )
  }

  if (type === 'main') return mainId === id
  if (type === 'bicolor') return bicolorId === id
  if (type === 'cuffsAndCollar') return cuffsAndCollarTextureId === id
  if (type === 'innerContrast') return innerContrastId === id
}

const ChangeTexture = ({
  id,
  name,
  type,
  previewUrl,
  url,
}: ChangeTextureProps) => {
  const {
    bicolorId,
    cuffsAndCollarTextureId,
    innerContrastId,
    mainId,
    setTexture,
    setConfiguratorTab,
    setShareParams,
  } = useTextures((state) => ({
    bicolorId: state.bicolorTexture?.id,
    cuffsAndCollarTextureId: state.collarTexture?.id,
    innerContrastId: state.innerContrastTexture?.id,
    mainId: state.mainTexture?.id,
    setTexture: state.setTexture,
    setConfiguratorTab: state.setConfiguratorTab,
    setShareParams: state.setShareParams,
  }))

  const { width } = useViewportSize()

  const isSelected =
    bicolorId && cuffsAndCollarTextureId && innerContrastId && mainId
      ? getIsSelected(id, type, {
          bicolorId,
          cuffsAndCollarTextureId,
          innerContrastId,
          mainId,
        })
      : false

  const handleClick = async () => {
    if (!isSelected) {
      await setTexture({ id, name, url })

      setShareParams();

      if (width <= 768) setConfiguratorTab('closed')
    }
  }

  return (
    <Container onClick={handleClick}>
      <ImageContainer active={isSelected}>
        <Thumbnail style={{ backgroundImage: `url(${previewUrl})` }} />
      </ImageContainer>

      <Label>{name}</Label>
    </Container>
  )
}

export default ChangeTexture
