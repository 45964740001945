import ClientBase from '../lib/befeni-fabric-api-wrapper.esm.js';

const baseUrl = (process.env.REACT_APP_CONSULTANT_API ?? '/');

const config = {
  shopBaseUrl: baseUrl,
  apiBaseUrl: process.env.REACT_APP_PRODUCTION_API
};

const client = new ClientBase(config);
client.updateAccessTokenDirectly(window.access_token);

export default client