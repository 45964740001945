import { useGLTF } from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import useTextures from '../../../hooks/useTextures';

type GLTFResult = GLTF & {
  nodes: {
    außen: THREE.Mesh;
    knopf_außen_links: THREE.Mesh;
    knopf_außen_rechts: THREE.Mesh;
    knopf_innen: THREE.Mesh;
    kragen_außen: THREE.Mesh;
    kragen_innen: THREE.Mesh;
  };
};

const ModernButtonDown = ({ ...props }: JSX.IntrinsicElements['group']) => {
  const { nodes } = useGLTF('./build/models/modern_button_down.glb') as unknown as GLTFResult;

  const collarTexture = useTextures((store) => store.collarTexture?.texture);
  const innerCollarTexture = useTextures((store) => store.innerCollarTexture?.texture);
  const buttonColor = useTextures((store) => store.buttonTexture?.hex_code);

  return (
    <group {...props} dispose={null} position={[-0.04, 67.08, 5.2]}>
      <group>
        <mesh geometry={nodes.außen.geometry} position={[-0.39, 3, -1.88]} rotation={[0.63, 1.25, 1.71]}>
          <meshStandardMaterial map={collarTexture} />
        </mesh>

        <mesh geometry={nodes.knopf_außen_links.geometry} position={[-3.6, -1.63, 1.05]} rotation={[-2.35, -0.32, -0.89]}>
          <meshStandardMaterial map={collarTexture} />
        </mesh>

        <mesh geometry={nodes.knopf_außen_rechts.geometry} position={[3.85, -1.34, 0.58]} rotation={[-1.58, -0.83, 1.18]}>
          <meshStandardMaterial map={collarTexture} />
        </mesh>

        <mesh geometry={nodes.knopf_innen.geometry} position={[0.04, -67.08, -5.2]} rotation={[-Math.PI / 2, 0, 0]}>
          <meshStandardMaterial color={buttonColor} />
        </mesh>

        <mesh geometry={nodes.kragen_außen.geometry} position={[0.04, -67.08, -5.2]} rotation={[-Math.PI / 2, 0, 0]}>
          <meshStandardMaterial map={collarTexture} />
        </mesh>

        <mesh geometry={nodes.kragen_innen.geometry} position={[0.04, -67.08, -5.2]} rotation={[-Math.PI / 2, 0, 0]}>
          <meshStandardMaterial map={innerCollarTexture} />
        </mesh>
      </group>
    </group>
  );
};

useGLTF.preload('./build/models/modern_button_down.glb');

export default ModernButtonDown;
