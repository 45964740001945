import Base from '../base';
import { Builder } from '../builder';
import { ResultType, ConsultantSystemAuthResult, AccessToken } from '../utils';


export class AuthenticationTrait extends Base {

	/**
	 * This method check the authentication status of the user then:
	 * - issue a visitor access token if the user is not logged in
	 * - issue a password grant token if the user is already logged in
	 * 
	 * This method should only be called when the page load for the first time to avoid any 419 error (TOO_MANY_REQUEST).
	 * 
	 */ 
	async refreshAccessToken(): Promise<AccessToken> {

		const result = await this._loginToConsultantSystem('', '', 'consultant');
		const token = <AccessToken>result.data;
		this.updateAccessToken(token);
		return token;
	}
	
	/**
	 * Authenticate the user with the Consultant System, 
	 * - if the authentication is a success, the Consultant System will request an access token to the Production System
	 * - if not, an error is thrown
	 * 
	 * @param {string} email
	 * @param {string} password
	 * @param {string} userType
	 */ 
	async getUserAccessToken(email: string, password: string, userType: 'consultant' | 'client'): Promise<AccessToken>  {

		const result = await this._loginToConsultantSystem(email, password, userType);

		if(result.status) {
			const token = <AccessToken>result.data;
			this.updateAccessToken(token);
			return token;

		} else {
			throw new Error('Invalid Consultant System credentials');
		}
	}

	/**
	 * This helper send an login request to the Consultant System.
	 * Ti also help to check if a user session already exists by sending a empty email and password.
	 * 
	 * If a user is already logged in, it will return a status = TRUE and a user access_token
	 * If a user is not logged in, it will return a status = FALSE  and a visitor access_token
	 */ 
	async _loginToConsultantSystem(email: string, password: string, userType: 'consultant' | 'client'): Promise<ResultType> {

		let builder = <Builder>this.getBuilder('Builder');

		const results = await builder.execute("post", 'shirt_gallery/login.php', {
			email        	: email,
			password     	: password,
			user_type    	: userType
		}, true);

		if(!results) {
			throw new Error('Error while trying to login to the Consultant System');
		}

		return <ResultType>results.getResults();
	}
}