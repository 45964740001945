import { createStitches } from '@stitches/react';

export const { getCssText, styled, css, keyframes } = createStitches({
  media: {
    mobile: 'screen and (max-width: 575.98px)',
    landscapeMobile: 'screen and (max-width: 767.98px)',
    tablet: 'screen and (max-width: 991.98px)',
    desktop: 'screen and (max-width:  1199.98px)',
  },
  utils: {
    center: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  },
});
