import React from 'react';

import NavBar from './components/NavBar';
import ShirtConfigurator from './components/ShirtConfigurator';
import ShirtScene from './components/ShirtScene';
import useTextures from './hooks/useTextures';
import { styled } from './styles/stitches.config';
import LoginModal from './components/LoginModal';

const Container = styled('div', {
  maxWidth: 1150,
  width: '100%',
  margin: '0 auto',
  height: 'calc(100vh - 50px)',
});

function App() {
  const displayLoginModal = useTextures(state => state.displayLoginModal)

  return (
    <>
      <NavBar />
      
      {displayLoginModal ? <LoginModal /> : null}

      <Container>
        <ShirtScene />

        <ShirtConfigurator />
      </Container>
    </>
  );
}

export default App;
