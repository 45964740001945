import Base from '../base';

import Dictionary, { PaginationDetails } from '../collection';
import { GenericResult, CollectionResultType, ExtendedCollectionResultType, ResultType } from '../utils';
import { Fabric, FabricBook } from '../instance';
import { Builder } from '../builder';


export type FabricBookFilters = {
	fabricIds: number[],
	categories: [],
	searchTerm: string|null,
	myFavoriteOnly: boolean,
	myBooksOnly: boolean,
	curatedBooksOnly: boolean
};

type FabricBookVisibility = 'public' | 'private' | 'inactive';


export class FabricBookTrait extends Base {

	/**
	 * Get the list of fabric books
	 * 
	 * @param {FabricBookFilters} filters
	 * @param {number} consultantId
	 * @param {number} clientId
	 * @param {string} orderField
	 * @param {string} orderDirection
	 * @param {number} page
	 * @param {number} perPage
	 * 
	 * @return Promise<Dictionary<ShirtGalleryItem>>
	 */ 
	async getFabricBooks(filters: FabricBookFilters, orderField: string, orderDirection:  'asc' | 'desc', page: number, perPage: number): Promise<Dictionary<FabricBook>>  {

		const collection = new Dictionary<FabricBook>();

		let builder = <Builder>this.getBuilder()
			.page(page).resultsPerPage(perPage)
			.clearSortBy()
			.sortBy(orderField, orderDirection);

		const results = await builder.execute("get", 'fabrics/book', {
            fabrics      : filters.fabricIds.join(','),
			categories	 : filters.categories.join(','),
            term      	 : filters.searchTerm,
            my_favorites : filters.myFavoriteOnly,
			my_books	 : filters.myBooksOnly,
			curated_books: filters.curatedBooksOnly
		});

        if(!results) {
            return collection;
        } 

        const resArr = <ExtendedCollectionResultType>results.getResults();
        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <FabricBook>FabricBook.createFromRaw(result, 'FabricBook'));
            count++;
        })
		collection.setPagination(<PaginationDetails>resArr.data.pagination);
		return collection;
	}

	/**
	 * Get the list of my books
	 * 
	 * @param {string} orderField
	 * @param {string} orderDirection
	 * @param {number} page
	 * @param {number} perPage
	 */ 
	async getMyBooks(orderField: string, orderDirection:  'asc' | 'desc', page: number, perPage: number): Promise<Dictionary<FabricBook>> {
		const collection = new Dictionary<FabricBook>();

		let builder = <Builder>this.getBuilder();


		const results = await builder.execute("get", 'fabrics/book', {
            my_books : true
		});

		if(!results) {
            return collection;
        }

        const resArr = <CollectionResultType>results.getResults();
        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <FabricBook>FabricBook.createFromRaw(result, 'FabricBook'));
            count++;
        })
		collection.setPagination(<PaginationDetails>resArr.data.pagination);

		return collection;
	}

	/**
	 * Get book details
	 * 
	 * @param {number} fabricBookId
	 */ 
	async fabricBookGetDetails(fabricBookId: number): Promise<FabricBook|null> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("get", 'fabrics/book/' + fabricBookId, {});

		if(!result) {
            return null;
        }

        const data = (<ResultType>result.getResults()).data;

        return <FabricBook>FabricBook.createFromRaw(data, 'FabricBook');
	}

	/**
	 * Get book details by slug
	 * 
	 * @param {string} fabricBookSlug
	 */ 
	async fabricBookGetDetailsBySlug(fabricBookSlug: string): Promise<FabricBook|null> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("get", 'fabrics/book/slug/' + fabricBookSlug, {});

		if(!result) {
            return null;
        }

        const data = (<ResultType>result.getResults()).data;

        return <FabricBook>FabricBook.createFromRaw(data, 'FabricBook');
	}

	/**
	 * Create a new book
	 * 
	 * @param {string}  title      - The title of the book
	 * @param {number}  categoryId - The parent category of the book
	 * @param {FabricBookVisibility} visibility  - Tell if the book should be included in search result
	 * @param {string}  coverColor - The cover color of the book
	 * @param {File}    coverImage - The vover image of the book
	 */ 
	async fabricBookCreate(title: string, categoryId: number, visibility: FabricBookVisibility, coverColor: string, coverImage: File|null = null, fabricIds: number[] = []): Promise<FabricBook|null> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("post", 'fabrics/book', {
            title      			    : title,
            fabric_book_category_id : categoryId,
			visibility 				: visibility,
            book_cover_color		: coverColor,
            image 					: coverImage,
            fabrics 				: fabricIds
		});

		if(!result) {
            return null;
        }

        const data = (<ResultType>result.getResults()).data;

        return <FabricBook>FabricBook.createFromRaw(data, 'FabricBook');
	}

	/**
	 * Update a fabric book
	 *
	 * @param {number}  fabricBookId - The id of the book
	 * @param {string}  title        - The title of the book
	 * @param {number}  categoryId   - The parent category of the book
	 * @param {FabricBookVisibility} visibility - Tell if the book should be included in search result
	 * @param {string}  coverColor   - The cover color of the book
	 * @param {File}    coverImage   - The vover image of the book
	 */
	async fabricBookUpdate(fabricBookId: number, title: string, categoryId: number, visibility: FabricBookVisibility, coverColor: string, coverImage: File|null = null): Promise<FabricBook|null> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("put", 'fabrics/book/' + fabricBookId, {
            title      			    : title,
            fabric_book_category_id : categoryId,
			visibility 				: visibility,
            book_cover_color		: coverColor,
            image 					: coverImage
		});

		if(!result) {
            return null;
        }

        const data = (<ResultType>result.getResults()).data;

        return <FabricBook>FabricBook.createFromRaw(data, 'FabricBook');
	} 

	/**
	 * Update the cover image of the book.
	 * 
	 * @param {number}  fabricBookId - The id of the book
	 * @param {File}    coverImage   - The vover image of the book
	 */ 
	async fabricBookUploadCover (fabricBookId: number, coverImage: File): Promise<FabricBook|null> {
		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("post", 'fabrics/book/' + fabricBookId + '/cover', {
            image : coverImage
		});

		if(!result) {
            return null;
        }

        const data = (<ResultType>result.getResults()).data;

        return <FabricBook>FabricBook.createFromRaw(data, 'FabricBook');
	}

	/**
	 * Delete a fabric book
	 * 
	 * @param {number}  fabricBookId - The id of the book
	 */
	async fabricBookDelete(fabricBookId: number): Promise<boolean> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("delete", 'fabrics/book/' + fabricBookId, {});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Add fabrics to a book
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 * @param {Array[number]} fabricIds - List of fabric ids to be added to the book
	 */ 
	async fabricBookAddFabrics(fabricBookId: number, fabricIds: number[]): Promise<boolean> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("post", 'fabrics/book/' + fabricBookId + '/fabrics', {
			fabrics: fabricIds
		});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status == 'OK';
	}

	/**
	 * Remove fabrics from a book
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 * @param {Array[number]} fabricIds - List of fabric ids to be removed from the book
	 */ 
	async fabricBookRemoveFabrics(fabricBookId: number, fabricIds: number[]): Promise<boolean> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("delete", 'fabrics/book/' + fabricBookId + '/fabrics', {
			fabrics: fabricIds
		});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Update the order of appearance of fabrics in a book
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 * @param {Array[number]} fabricIds - List of fabric ids of the book in a different order
	 */ 
	async fabricBookUpdateFabricsOrder(fabricBookId: number, fabricIds: number[]): Promise<boolean> {

		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("put", 'fabrics/book/' + fabricBookId + '/fabrics', {
			fabrics: fabricIds
		});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Add a like to a book
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 */ 
	async fabricBookAddLike(fabricBookId: number): Promise<boolean> {
		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("post", 'fabrics/book/' + fabricBookId + '/like', {});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Remove a like from a book
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 */ 
	async fabricBookRemoveLike(fabricBookId: number): Promise<boolean> {
		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("delete", 'fabrics/book/' + fabricBookId + '/like', {});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Add a book to my favorites
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 */ 
	async fabricBookAddFavorite(fabricBookId: number): Promise<boolean> {
		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("post", 'fabrics/book/' + fabricBookId + '/favorite', {});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Remove a book from my favorites
	 * 
	 * @param {number}  fabricBookId    - The id of the book
	 */ 
	async fabricBookRemoveFavorite(fabricBookId: number): Promise<boolean> {
		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("delete", 'fabrics/book/' + fabricBookId + '/favorite', {});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

	/**
	 * Get the data required to display the book explorer page
	 *
	 * @param count
	 */
	async getExplorerBootstrapData(count: number = 0): Promise<any> {
		let builder = <Builder>this.getBuilder();

		let params = count > 0 ? { count : count } : {};

		const result = await builder.execute("get", 'fabrics/book/explorer-data', params);

		if(!result) {
			return null;
		}
		return (<ResultType>result.getResults()).data;
	}


	/**
	 * Send list of fabrics by email
	 * 
	 * @param {string} recipientEmail
	 * @param {string} recipientName
	 * @param {string} subject
	 * @param {any[]} fabricData
	 * @param {string} message
	 */ 
	async fabricBookSendByEmail(recipientEmail: string, recipientName: string, subject: string, fabricData: any[], message: string): Promise<boolean> {


		let builder = <Builder>this.getBuilder();

		const result = await builder.execute("post", 'fabrics/book/send-fabrics', {
			recipient_email: recipientEmail,
			recipient_name: recipientName,
			subject: subject,
			fabrics: JSON.stringify(fabricData),
			message_body: message
		});

		if(!result) {
            return false;
        }

		return (<ResultType>result.getResults()).status === 'OK';
	}

}