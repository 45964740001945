export interface collarListProps {
  id: number;
  url: `${string}.min.svg`;
  label: string;
  repeat?: [number, number];
}

const collarList: collarListProps[] = [
  { id: 1, url: 'kent_classic.min.svg', label: 'kent_classic', repeat: [0.5, 0.5] },
  { id: 2, url: 'kent_modern.min.svg', label: 'kent_modern', repeat: [0.5, 0.5] },
  { id: 3, url: 'bristol.min.svg', label: 'bristol', repeat: [0.5, 0.5] },
  { id: 4, url: 'stehkragen.min.svg', label: 'stand_up_collar', repeat: [0.5, 0.5] },
  { id: 5, url: 'haifisch_small.min.svg', label: 'shark_small', repeat: [0.5, 0.5] },
  // { id: 6, url: 'haifisch_medium.min.svg', label: 'shark_medium', repeat: [0.5, 0.5] },
  { id: 7, url: 'haifisch_large.min.svg', label: 'shark_large', repeat: [0.5, 0.5] },
  // { id: 8, url: 'tab.svg', label: 'Tabkragen', repeat: [0.5, 0.5] },
  { id: 9, url: 'button_down.min.svg', label: 'button_down', repeat: [0.5, 0.5] },
  { id: 10, url: 'under_button_down.min.svg', label: 'under_button_down', repeat: [0.4, 0.4] },
  { id: 11, url: 'under_button_down_large.min.svg', label: 'under_button_down_large', repeat: [0.5, 0.5] },
  // { id: 12, url: 'smoking.svg', label: 'Smokingkragenennn' , repeat: [0.5, 0.5]},
  { id: 13, url: 'modern_down.min.svg', label: 'modern_down', repeat: [0.5, 0.5] },
];

export default collarList;
