import React from 'react';
import ZoomOut from '../../images/zoomOut.svg';

const ZoomOutIcon = () => {
  return (
    <img src={ZoomOut} alt="" />
  );
};

export default ZoomOutIcon;
