import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

export type FabricImageType = 'konfigurator_base' | 'konfigurator_swatch' | 'konfigurator_contrast' | 'konfigurator_collar' 
            | 'backend' | 'thumbnail' | 'client_shop' | 'main' 
            | 'mannequin' | 'flatlay' | 'into_collar' | 'fabric_on_roll' | 'fabric_with_wrinkles' | 'tile';

export class FabricImage extends InstanceAbstract implements InstanceInterface {

    /**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /**
     * @type {number}
     */
    private id: number = 0;

    /**
     * @type {number}
     */
    private fabric_id: number = 0;

    /**
     * @type {FabricImageType}
     */
    private type: FabricImageType = 'main';

    /**
     * @type {string}
     */
    private image_url: string = '';

    /**
     * @type {string}
     */
    private variant: string = '';

    /**
     * @type {string}
     */
    private created_at: string = '';

    /**
     * @type {string}
     */
    private updated_at: string = '';

    /**
     * @return {number}
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param  {number}} id
     * @return {FabricImage}
     */
    public setId(id: number): FabricImage {
        this.id = id;
        return this;
    }

    /**
     * @return {number}
     */
    public getFabricId(): number {
        return this.fabric_id;
    }

    /**
     * @param  {number} fabric_id
     * @return {FabricImage}
     */
    public setFabricId(fabric_id: number): FabricImage {
        this.fabric_id = fabric_id;
        return this;
    }

    /**
     * @return {FabricImageType}
     */
    public getType(): FabricImageType {
        return this.type;
    }

    /**
     * @param  {FabricImageType} type
     * @return {FabricImage}         
     */
    public setType(type: FabricImageType): FabricImage {
        this.type = type;
        return this;
    }

    /**
     * @return {string}
     */
    public getImageUrl(): string {
        return this.image_url;
    }

    /**
     * @param  {string} image_url
     * @return {FabricImage}          
     */
    public setImageUrl(image_url: string): FabricImage {
        this.image_url = image_url;
        return this;
    }

    /**
     * @return {string}
     */
    public getVariant(): string {
        return this.variant;
    }

    /**
     * @param  {string} variant
     * @return {FabricImage}          
     */
    public setVariant(variant: string): FabricImage {
        this.variant = variant;
        return this;
    }

    /**
     * @return {string}
     */
    public getCreatedAt(): string {
        return this.created_at;
    }

    /**
     * @param  {string} created_at
     * @return {FabricImage}           
     */
    public setCreatedAt(created_at: string): FabricImage {
        this.created_at = created_at;
        return this;
    }

    /**
     * @return {string}
     */
    public getUpdatedAt(): string {
        return this.updated_at;
    }

    /**
     * @param  {string} updated_at
     * @return {FabricImage}
     */
    public setUpdatedAt(updated_at: string): FabricImage {
        this.updated_at = updated_at;
        return this;
    }
    
}