import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import de from "./languages/de.json";

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

interface CustomWindow extends Window {
  initialLanguage?: string;
}
const initialLanguage = (window as CustomWindow).initialLanguage!;

i18n.use(initReactI18next).init({
  resources,
  lng: initialLanguage ? initialLanguage : "de",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
