import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';
import { ShirtBootstrapConfig, BootstrapShirtActionData } from '../gallery';


export class ShirtBootstrapData extends InstanceAbstract implements InstanceInterface {

	/**
     * List of data used to create the advanced filters of the 
     * shirt gallery
	 * @type {ShirtBootstrapConfig}
	 */
	private configs: ShirtBootstrapConfig = {} as ShirtBootstrapConfig;

	/**
	 * List of liked and wishlisted shirts for the current 
	 * user
	 * @type {BootstrapShirtActionData}
	 */
	private user_actions: BootstrapShirtActionData = {} as BootstrapShirtActionData;

	/**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /**
     * @return {ShirtBootstrapConfig}
     */ 
	public getConfigs(): ShirtBootstrapConfig {
		return this.configs;
	}

	/**
	 * @param {ShirtBootstrapData}
	 * @return {ShirtBootstrapData}
	 */ 
	public setConfigs(configs: ShirtBootstrapConfig): ShirtBootstrapData {
		this.configs = configs
		return this;
	}

	/**
	 * @return {BootstrapShirtActionData}
	 */ 
	public getUserActions(): BootstrapShirtActionData {
		return this.user_actions;
	}

	/**
	 * @param {BootstrapShirtActionData}
	 * @return {ShirtBootstrapData}
	 */ 
	public setUserActions(user_actions: BootstrapShirtActionData): ShirtBootstrapData {
		this.user_actions = user_actions;
		return this;
	}

}