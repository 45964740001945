import Base from '../base'
import Dictionary from '../collection';
import { GenericResult, ResultType } from '../utils';
import { ThreadColor } from '../instance';
import { ThreadBuilder } from '../builder';

export class ThreadTrait extends Base {

    /**
     * Get a list of all threads
     * @param {string} orderField
     * @param {string} orderDirection
     * @return {Promise<Dictionary<ThreadColor>>} 
     */
    async getAllThreads(orderField: string = 'order_color', orderDirection: 'asc' | 'desc' = 'asc'): Promise<Dictionary<ThreadColor>> {
        const collection = new Dictionary<ThreadColor>();

        const builder = <ThreadBuilder>this.getBuilder('ThreadBuilder')
            .select(['id', 'legacy_id', 'title_en', 'title_de', 'title_th', 'title_translatable', 
                'internal_number', 'embroidery_only', 'chooseable', 'created_at', 'updated_at'])
            .sortBy(orderField, orderDirection);

        const results = await builder.execute("get", 'fabrics/thread_colors', {});

        if(!results) {
            return collection;
        } 

        const resArr = <ResultType>results.getResults();
        var count = 1;
        (resArr.data as Object[]).map(result => {
            collection.addItem(count.toString(), <ThreadColor>ThreadColor.createFromRaw(result, 'ThreadColor'));
            count++;
        })

        return collection;
    }

    /**
     * Get a single thread color
     * @param {number} id
     * @return {Promise<ThreadColor>}
     */ 
    async getThread(id:number): Promise<ThreadColor> {
        const builder = <ThreadBuilder>this.getBuilder('ThreadBuilder')
            .select(['id', 'title_en', 'title_th',
                'title_de', 'title_translatable', 'internal_number', 'order_color']);

        const results = await builder.execute("get", 'fabrics/thread_colors/' + id, {});

        if(!results) {
            throw new Error('Unable to find thread');
        }
        const result = <ResultType>results.getResults();
        return <ThreadColor>ThreadColor.createFromRaw(result.data, 'ThreadColor');
    }
}


