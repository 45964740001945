import React from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const ChangeLanguageAction = () => {
  const { i18n } = useTranslation();

  async function changeLanguageHandler(lang: string) {
    i18n.changeLanguage(lang);
    await axios.post("/hello.php?language=" + lang);
  }

  return (
    <div className="change-language-action">
      <img
        onClick={() => changeLanguageHandler("de")}
        src="/images/flag_de.png"
      />
      <img
        onClick={() => changeLanguageHandler("en")}
        src="/images/flag_en.png"
      />
    </div>
  );
};

export default ChangeLanguageAction;
