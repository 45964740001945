export let translations = {
	// put translations in EN here
	"[[Cotton]]": "Cotton",
	"[[Polyester]]": "Polyester",
	"[[Spandex]]": "Spandex",
	"[[Other]]": "Other",
	"[[Upcycled Polyester]]": "Upcycled Polyester",
	"[[Linen]]": "Linen",
	"[[Bamboo]]": "Bamboo",
	"[[Tencel]]": "Tencel",
	"[[Rayon]]": "Rayon",
	"[[Nylon]]": "Nylon",
	"available": "Available",
	"soon_sold_out_coming_back": "Soon Sold Out",
	"soon_sold_out_not_coming_back": "Soon Sold Out",
	"sold_out_coming_back": "Sold Out",
	"sold_out_not_coming_back": "Sold Out",
	"inactive": "Inaktiv",
	"[[stretch_fabric]]": "Stretch",
	"[[jersey_fabric]]": "Jersey",
	"no_next_delivery": "Currently no delivery",
	"next_delivery": "Next delivery",
	"fabric_available": "Available", 
	"fabric_bigger_amounts_available": "Bigger amounts available", 
	"fabric_soon_sold_out": "Soon sold out", 
	"fabric_sold_out": "Sold out",
	"beginningOf": "Beginning of ",
	"middleOf": "Middle of ",
	"endOf": "End of ",
	"January": "January",
	"February": "February",
	"March": "March",
	"April": "April",
	"May": "May",
	"June": "June",
	"July": "July",
	"August": "August",
	"September": "September",
	"October": "October",
	"November": "November",
	"December": "December",
	"availableNote": "Permanently available",
	"comingBackNote": "Currently unavailable, will be back soon.",
	"notComingBackNote": "No longer available, won\'t come back."
}