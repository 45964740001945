import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';
import { FabricCompositionLabel } from './FabricCompositionLabel';
import { ButtonColor } from './ButtonColor';
import { ThreadColor } from './ThreadColor';
import { EmbroideryThreadColor } from './EmbroideryThreadColor';
import { FabricImage } from './FabricImage';
import {ShirtGalleryItem} from "./ShirtGalleryItem";

export type FabricPattern = 'solid' | 'striped' | 'checked' | 'dotted' | 'camouflage' | 'geometric' | 'paisley' | 'other' | 'floral' | 'herringbone' | 'dobby' | 'abstract';
export type FabricCurrency = 'THB' | 'USD' | 'EUR';
export type FabricType = 'Main' | 'Seasonal' | 'Contrast' | 'Other';
export type FabricAvailability = 'available' | 'sold_out_coming_back' | 'sold_out_not_coming_back' | 'soon_sold_out_coming_back' | 'soon_sold_out_not_coming_back' | 'not_released_yet' | 'inactive'
export type FabricWeave = "Poplin" | "Dobby" | "Twill" | "Oxford" | "Satin" | "Plain" | "Mix" | "Bamboo" | "Heringbone" | "Hering" | "Flanell" | "Bedford Cord" | "Big Twill";

export class Fabric extends InstanceAbstract implements InstanceInterface {

    /**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /** ====== Class Properties ====== */

    /**
     * @type {number}
     */
    private id: number = 0;

    /**
     * @type {string}
     */
    private name: string = '';

    /**
     * @type {string}
     */
    private image_url: string = '';

    /**
     * @type {FabricPattern}
     */
    private pattern: FabricPattern = 'solid'

    /**
     * @type {number}
     */
    private comfort: number = 1;

    /**
     * @type {number}
     */
    private ironing: number = 1;

    /**
     * @type {number}
     */
    private fabric_composition_label_id: number = 0;

    /**
     * @type {string}
     */
    private default_weave: string = '';

    /**
     * @type {string}
     */
    private default_weight: string = '';

    /**
     * @type {'business' | 'casual' = 'business'}
     */
    private usage_type: 'business' | 'casual' = 'business';

    /**
     * @type {string}
     */
    private default_finish: string = '';

    /**
     * @type {number}
     */
    private button_color_id: number = 0;

    /**
     * @type {number}
     */
    private thread_color_id: number = 0;

    /**
     * @type {number}
     */
    private embroidery_thread_color_id: number = 0;

    /**
     * @type {number}
     */
    private white_fabric_id: number = 0;

    /**
     * @type {boolean}
     */
    private sample_book: boolean = false;

    /**
     * @type {number}
     */
    private position_book: number = 0;

    /**
     * @type {boolean}
     */
    private matching: boolean = false;

    /**
     * @type {string}
     */
    private interlining_color: string = '';

    /**
     * @type {string}
     */
    private interlining_softness: 'soft' | 'hard' = 'hard';

    /**
     * @type {boolean}
     */
    private is_shirt_fabric: boolean = false;

    /**
     * @type {boolean}
     */
    private is_stretch_fabric: boolean = false;

    /**
     * @type {boolean}
     */
    private is_jersey_fabric: boolean = false;

    /**
     * @type {string}
     */
    private special_description_suffix: string = '';

    /**
     * @type {string}
     */
    private description: string = '';

    /**
     * @type {string}
     */
     private description_en: string = '';

    /**
     * @type {string}
     */
    private certificate: string = '';

    /**
     * @type {string}
     */
    private fabric_class: string = '';

    /**
     * @type {'male'|'female'}
     */
    private gender_suitable_for: 'male' | 'female' = 'male';

    /**
     * @type {'Main'|'Seasonal'|'Contrast'|'Other'}
     */
    private type: FabricType = 'Main';

    /**
     * @type {string}
     */
    private introduced_date: string = '';

    /**
     * @type {string}
     */
    private discontinued_date: string = '';

    /**
     * {FabricAvailability}
     */
    private status: FabricAvailability = 'inactive';

    /**
     * @type {string}
     */
    private note: string = '';

    /**
     * @type {'keep'|'out'}
     */
    private future: 'keep' | 'out' = 'keep';

    /**
     * @type {string}
     */
    private created_at: string = '';

    /**
     * @type {string}
     */
    private updated_at: string = '';

    /**
     * @type {string}
     */
    private delivery_date: string = '';

    /**
     * @type {number}
     */
    private out_of_stock_date: number = 0;

    /**
     * @type {number}
     */
    private remaining_shirts: number = 0;


    /** ====== Relationships ====== */

    /**
     * These are a list of relationships that will be searched
     * for in any raw data passed to the constructor of a Fabric
     * instance. From there it will try to populate these 
     * relationships with instances of the relationship.
     *
     * See InstanceAbstract for an explanation of the format
     * below
     * @type {string[]}
     */
    public relationships: { [index: string]: string} = {
        'buttonColor': 'buttonColor',
        'category': 'category',
        'embroideryThreadColor': 'embroideryThreadColor',
        'fabricCompositionLabel': 'fabricCompositionLabel',
        // 'fabricSupplier': 'fabricSupplier',
        'rolls': 'rolls',
        'threadColor': 'threadColor',
        'fabricImage': 'images',
        'shirtGalleryItem': 'galleries'
    }

    /**
     * Each fabric has a button color, this property stores
     * a reference to the ButtonColor instance belonging
     * to this Fabric
     * @type {ButtonColor}
     */
    private buttonColor: ButtonColor = {} as ButtonColor;

    /**
     * Each fabric has a thread color, this property stores
     * a reference to the ThreadColor instance belonging
     * to this Fabric
     * @type {ThreadColor}
     */
    private threadColor: ThreadColor = {} as ThreadColor;


    /**
     * Each fabric has a embroidery thread color, this property stores
     * a reference to the ThreadColor instance belonging
     * to this Fabric
     * @type {EmbroideryThreadColor}
     */
    private embroideryThreadColor: EmbroideryThreadColor = {} as EmbroideryThreadColor;

    /**
     * Each fabric has a composition label, this property stores
     * a reference to the FabricCompositionLabel instance belonging
     * to this Fabric
     * @type {FabricCompositionLabel}
     */
    private compositionLabel: FabricCompositionLabel = {} as FabricCompositionLabel;

    /**
     * Each fabric has multiple images, this property is an array
     * of FabricImage instances belonging to this Fabric
     * @type {Array<FabricImage>}
     */
    private fabricImages: Array<FabricImage> = [] as Array<FabricImage>;

    /**
     * Each fabric may have multiple shirt galleries, this property is an array
     * of ShirtGalleryItem instances belonging to this Fabric
     * @type {Array<ShirtGalleryItem>}
     */
    private shirtGalleries: Array<ShirtGalleryItem> = [] as Array<ShirtGalleryItem>;

    /**
     * @return {FabricCompositionLabel}
     */
    public getFabricCompositionLabel(): FabricCompositionLabel {
        return this.compositionLabel;
    }

    /**
     * @param  {FabricCompositionLabel} compositionLabel
     * @return {Fabric}                                 
     */
    public setFabricCompositionLabel(compositionLabel: FabricCompositionLabel): Fabric {
        this.compositionLabel = compositionLabel;
        return this;
    }

    /**
     * @return {Array<FabricImage>}
     */
    public getImages(): Array<FabricImage> {
        return this.fabricImages;
    }

    /**
     * @param  {FabricImage} image
     * @return {Fabric}           
     */
    public setImages(image: FabricImage): Fabric {
        this.fabricImages.push(image)
        return this;
    }

    /**
     * @param  {FabricImage) => FabricImage} callback
     * @return {Array<FabricImage>}
     */
    public getImage(callback: (item: FabricImage) => FabricImage): Array<FabricImage> {
        return this.fabricImages.filter(callback);
    }

    /**
     * @return {Array<ShirtGalleryItem>}
     */
    public getGalleries(): Array<ShirtGalleryItem> {
        return this.shirtGalleries;
    }

    /**
     * @param  {ShirtGalleryItem} shirt
     * @return {Fabric}
     */
    public setGalleries(shirt: ShirtGalleryItem): Fabric {
        this.shirtGalleries.push(shirt)
        return this;
    }

    /**
     * @param  {ShirtGalleryItem) => ShirtGalleryItem} callback
     * @return {Array<ShirtGalleryItem>}
     */
    public getGallery(callback: (item: ShirtGalleryItem) => ShirtGalleryItem): Array<ShirtGalleryItem> {
        return this.shirtGalleries.filter(callback);
    }

    /** ====== Accessors and Mutators ====== */

    /**
     * @return mixed
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param mixed id
     * @return <Fabric>
     */
    public setId(id: number): Fabric {
        this.id = id;
        return this;
    }

    /**
     * @return mixed
     */
    public getName(): string {
        return this.name;
    }

    /**
     * @param mixed name
     * @return <Fabric>
     */
    public setName(name: string): Fabric {
        this.name = name;
        return this;
    }

    /**
     * @return mixed
     */
    public getImageUrl(): string {
        return this.image_url;
    }

    /**
     * @param mixed image_url
     *
     * @return <Fabric>
     */
    public setImageUrl(image_url: string): Fabric {
        this.image_url = image_url;
        return this;
    }

    /**
     * @return mixed
     */
    public getPattern(): FabricPattern {
        return this.pattern;
    }

    /**
     * @param mixed pattern
     *
     * @return <Fabric>
     */
    public setPattern(pattern: FabricPattern): Fabric {
        this.pattern = pattern;
        return this;
    }

    /**
     * @return mixed
     */
    public getComfort(): number {
        return this.comfort;
    }

    /**
     * @param mixed comfort
     *
     * @return <Fabric>
     */
    public setComfort(comfort: number): Fabric {
        this.comfort = comfort;
        return this;
    }

    /**
     * @return mixed
     */
    public getIroning(): number {
        return this.ironing;
    }

    /**
     * @param mixed ironing
     *
     * @return <Fabric>
     */
    public setIroning(ironing: number): Fabric {
        this.ironing = ironing;
        return this;
    }

    /**
     * @return mixed
     */
    public getFabricCompositionLabelId(): number {
        return this.fabric_composition_label_id;
    }

    /**
     * @param mixed fabric_composition_label_id
     * @return <Fabric>
     */
    public setFabricCompositionLabelId(fabric_composition_label_id: number): Fabric {
        this.fabric_composition_label_id = fabric_composition_label_id;
        return this;
    }

    /**
     * @return mixed
     */
    public getDefaultWeave(): string {
        return this.default_weave;
    }

    /**
     * @param mixed default_weave
     *
     * @return <Fabric>
     */
    public setDefaultWeave(default_weave: string): Fabric {
        this.default_weave = default_weave;
        return this;
    }

    /**
     * @return mixed
     */
    public getDefaultWeight(): string {
        return this.default_weight;
    }

    /**
     * @param mixed default_weight
     *
     * @return <Fabric>
     */
    public setDefaultWeight(default_weight: string): Fabric {
        this.default_weight = default_weight;
        return this;
    }

    /**
     * @return mixed
     */
    public getUsageType(): 'business' | 'casual' {
        return this.usage_type;
    }

    /**
     * @param mixed usage_type
     */
    public setUsageType(usage_type: 'business' | 'casual'): Fabric {
        this.usage_type = usage_type;
        return this;
    }

    /**
     * @return mixed
     */
    public getDefaultFinish(): string {
        return this.default_finish;
    }

    /**
     * @param mixed default_finish
     *
     * @return <Fabric>
     */
    public setDefaultFinish(default_finish: string): Fabric {
        this.default_finish = default_finish;
        return this;
    }

    /**
     * @return mixed
     */
    public getButtonColorId(): number {
        return this.button_color_id;
    }

    /**
     * @param mixed button_color_id
     *
     * @return <Fabric>
     */
    public setButtonColorId(button_color_id: number): Fabric {
        this.button_color_id = button_color_id;
        return this;
    }

    /**
     * @return mixed
     */
    public getButtonColor(): ButtonColor {
        return this.buttonColor;
    }

    /**
     * @param mixed buttonColor
     *
     * @return <Fabric>
     */
    public setButtonColor(buttonColor: ButtonColor): Fabric {
        this.buttonColor = buttonColor;
        return this;
    }

    /**
     * @return mixed
     */
    public getThreadColorId(): number {
        return this.thread_color_id;
    }

    /**
     * @param mixed thread_color_id
     *
     * @return <Fabric>
     */
    public setThreadColorId(thread_color_id: number): Fabric {
        this.thread_color_id = thread_color_id;
        return this;
    }

    /**
     * @return mixed
     */
    public getThreadColor(): ThreadColor {
        return this.threadColor;
    }

    /**
     * @param mixed threadColor
     *
     * @return <Fabric>
     */
    public setThreadColor(threadColor: ThreadColor): Fabric {
        this.threadColor = threadColor;
        return this;
    }

    /**
     * @return mixed
     */
    public getEmbroideryThreadColorId(): number {
        return this.embroidery_thread_color_id;
    }

    /**
     * @param mixed embroidery_thread_color_id
     *
     * @return <Fabric>
     */
    public setEmbroideryThreadColorId(embroidery_thread_color_id: number): Fabric {
        this.embroidery_thread_color_id = embroidery_thread_color_id;
        return this;
    }

    /**
     * @return mixed
     */
    public getEmbroideryThreadColor(): EmbroideryThreadColor {
        return this.embroideryThreadColor;
    }

    /**
     * @param mixed embroideryThreadColor
     *
     * @return <Fabric>
     */
    public setEmbroideryThreadColor(embroideryThreadColor: EmbroideryThreadColor): Fabric {
        this.embroideryThreadColor = embroideryThreadColor;
        return this;
    }

    /**
     * @return mixed
     */
    public getWhiteFabricId(): number {
        return this.white_fabric_id;
    }

    /**
     * @param mixed white_fabric_id
     *
     * @return <Fabric>
     */
    public setWhiteFabricId(white_fabric_id: number): Fabric {
        this.white_fabric_id = white_fabric_id;
        return this;
    }

    /**
     * @return mixed
     */
    public getSampleBook(): boolean {
        return this.sample_book;
    }

    /**
     * @param mixed sample_book
     *
     * @return <Fabric>
     */
    public setSampleBook(sample_book: boolean): Fabric {
        this.sample_book = sample_book;
        return this;
    }

    /**
     * @return mixed
     */
    public getPositionBook(): number {
        return this.position_book;
    }

    /**
     * @param mixed position_book
     *
     * @return <Fabric>
     */
    public setPositionBook(position_book: number): Fabric {
        this.position_book = position_book;
        return this;
    }

    /**
     * @return mixed
     */
    public getMatching(): boolean {
        return this.matching;
    }

    /**
     * @param mixed matching
     *
     * @return <Fabric>
     */
    public setMatching(matching: boolean): Fabric {
        this.matching = matching;
        return this;
    }

    /**
     * @return mixed
     */
    public getInterliningColor(): string {
        return this.interlining_color;
    }

    /**
     * @param mixed interlining_color
     *
     * @return <Fabric>
     */
    public setInterliningColor(interlining_color: string): Fabric {
        this.interlining_color = interlining_color;
        return this;
    }

    /**
     * @return mixed
     */
    public getInterliningSoftness(): 'soft'|'hard' {
        return this.interlining_softness;
    }

    /**
     * @param mixed interlining_softness
     *
     * @return <Fabric>
     */
    public setInterliningSoftness(interlining_color: 'soft'|'hard'): Fabric {
        this.interlining_color = interlining_color;
        return this;
    }

    /**
     * @return mixed
     */
    public getIsShirtFabric(): boolean {
        return this.is_shirt_fabric;
    }

    /**
     * @param mixed is_shirt_fabric
     *
     * @return <Fabric>
     */
    public setIsShirtFabric(is_shirt_fabric: boolean): Fabric {
        this.is_shirt_fabric = is_shirt_fabric;
        return this;
    }

    /**
     * @return mixed
     */
    public getIsStretchFabric(): boolean {
        return this.is_stretch_fabric;
    }

    /**
     * @param mixed is_stretch_fabric
     *
     * @return <Fabric>
     */
    public setIsStretchFabric(is_stretch_fabric: boolean): Fabric {
        this.is_stretch_fabric = is_stretch_fabric;
        return this;
    }

    /**
     * @return mixed
     */
    public getIsJerseyFabric(): boolean {
        return this.is_jersey_fabric;
    }

    /**
     * @param mixed is_jersey_fabric
     *
     * @return <Fabric>
     */
    public setIsJerseyFabric(is_jersey_fabric: boolean): Fabric {
        this.is_jersey_fabric = is_jersey_fabric;
        return this;
    }

    /**
     * @return mixed
     */
    public getSpecialDescriptionSuffix(): string {
        return this.special_description_suffix;
    }

    /**
     * @param mixed special_description_suffix
     *
     * @return <Fabric>
     */
    public setSpecialDescriptionSuffix(special_description_suffix: string): Fabric {
        this.special_description_suffix = special_description_suffix;
        return this;
    }

    /**
     * @return mixed
     */
    public getDescription(): string {
        return this.description;
    }

    /**
     * @param mixed description
     * @return <Fabric>
     */
    public setDescription(description: string): Fabric {
        this.description = description;
        return this;
    }

    /**
     * @return mixed
     */
     public getDescriptionEn(): string {
        return this.description_en;
    }

    /**
     * @param mixed description_en
     * @return <Fabric>
     */
    public setDescriptionEn(description_en: string): Fabric {
        this.description_en = description_en;
        return this;
    }

    /**
     * @return mixed
     */
    public getCertificate(): string {
        return this.certificate;
    }

    /**
     * @param mixed certificate
     * @return <Fabric>
     */
    public setCertificate(certificate: string): Fabric {
        this.certificate = certificate;
        return this;
    }

    /**
     * @return mixed
     */
    public getFabricClass(): string {
        return this.fabric_class;
    }

    /**
     * @param mixed fabric_class
     * @return <Fabric>
     */
    public setFabricClass(fabric_class: string): Fabric {
        this.fabric_class = fabric_class;
        return this;
    }

    /**
     * @return mixed
     */
    public getGenderSuitableFor(): 'male' | 'female' {
        return this.gender_suitable_for;
    }

    /**
     * @param mixed gender_suitable_for
     *
     * @return <Fabric>
     */
    public setGenderSuitableFor(gender_suitable_for: 'male' | 'female'): Fabric {
        this.gender_suitable_for = gender_suitable_for;
        return this;
    }

    /**
     * @return mixed
     */
    public getType(): FabricType {
        return this.type;
    }

    /**
     * @param mixed type
     *
     * @return <Fabric>
     */
    public setType(type: FabricType): Fabric {
        this.type = type;
        return this;
    }

    /**
     * @return mixed
     */
    public getIntroducedDate(): string {
        return this.introduced_date;
    }

    /**
     * @param mixed introduced_date
     *
     * @return <Fabric>
     */
    public setIntroducedDate(introduced_date: string): Fabric {
        this.introduced_date = introduced_date;
        return this;
    }

    /**
     * @return mixed
     */
    public getDiscontinuedDate(): string {
        return this.discontinued_date;
    }

    /**
     * @param mixed discontinued_date
     *
     * @return <Fabric>
     */
    public setDiscontinuedDate(discontinued_date: string): Fabric {
        this.discontinued_date = discontinued_date;
        return this;
    }

    /**
     * @return mixed
     */
    public getStatus(): FabricAvailability {
        return this.status;
    }

    /**
     * @param mixed status
     *
     * @return <Fabric>
     */
    public setStatus(status: FabricAvailability): Fabric {
        this.status = status;
        return this;
    }

    /**
     * @return mixed
     */
    public getNote(): string {
        return this.note;
    }

    /**
     * @param mixed note
     *
     * @return <Fabric>
     */
    public setNote(note: string): Fabric {
        this.note = note;
        return this;
    }

    /**
     * @return mixed
     */
    public getFuture(): 'keep' | 'out' {
        return this.future;
    }

    /**
     * @param mixed future
     *
     * @return <Fabric>
     */
    public setFuture(future: 'keep' | 'out'): Fabric {
        this.future = future;
        return this;
    }

    /**
     * @return mixed
     */
    public getCreatedAt(): string {
        return this.created_at;
    }

    /**
     * @param mixed created_at
     *
     * @return <Fabric>
     */
    public setCreatedAt(created_at: string): Fabric {
        this.created_at = created_at;
        return this;
    }

    /**
     * @return mixed
     */
    public getUpdatedAt(): string {
        return this.updated_at;
    }

    /**
     * @param updated_at
     * @return <Fabric>
     */
    public setUpdatedAt(updated_at: string): Fabric {
        this.updated_at = updated_at;
        return this;
    }

    /**
     * @return mixed
     */
    public getDeliveryDate(): string {
        return this.delivery_date;
    }

    /**
     * @param delivery_date
     * @return <Fabric>
     */
    public setDeliveryDate(delivery_date: string): Fabric {
        this.delivery_date = delivery_date;
        return this;
    }

    /**
     * @return number
     */
    public getOutOfStockDate(): number {
        return this.out_of_stock_date;
    }

    /**
     * @param out_of_stock_date
     * @return <Fabric>
     */
    public setOutOfStockDate(out_of_stock_date: number): Fabric {
        this.out_of_stock_date = out_of_stock_date;
        return this;
    }

    /**
     * @return number
     */
    public getRemainingShirts(): number {
        return this.remaining_shirts;
    }

    /**
     * @param remaining_shirts
     * @return <Fabric>
     */
    public setRemainingShirts(remaining_shirts: number): Fabric {
        this.remaining_shirts = remaining_shirts;
        return this;
    }

    /**
     * Get the special information that will be displayed along with fabric type, material, weave ... on the fabric details pages
     */ 
    public generateSpecialDescription(): string {
        let specialFieldComponents: Array<string> = [];

        if(this.is_stretch_fabric) {
            specialFieldComponents.push('[[stretch_fabric]]');
        }

        if(this.is_jersey_fabric) {
            specialFieldComponents.push('[[jersey_fabric]]');
        }

        if(this.special_description_suffix && this.special_description_suffix.length > 0) {
            specialFieldComponents.push(this.special_description_suffix);
        }

        return specialFieldComponents.join(', ');
    }
}