import React from 'react'

import { styled } from '../styles/stitches.config'
import ChangeLanguageAction from './ChangeLanguageAction'
import NextButton from './NextButton'

const Container = styled('nav', {
  backgroundColor: '#4b4b4b',
  height: '50px',
  center: '',
  position: 'relative'
})

const NavBar = () => {
  return (
    <Container>
      <img
        src="./build/logo.min.svg"
        alt="Befeni Logo"
        height={45}
        width={90.53}
      />

      <ChangeLanguageAction />

      <NextButton />
    </Container>
  )
}

export default NavBar
