import BuilderInterface from './BuilderInterface';
import { Builder } from './Builder';
import Base from '../base';
import { GenericResult } from '../utils';
import { HttpMethod } from '../types';

export class ThreadBuilder extends Builder implements BuilderInterface {

    private whereVals: { [index: string]: any } = {};

    /**
      * For API endpoints that do not implement the
      * GenericListRequest (?) class, this allows us to set
      * fields 
      * @param  {string} field
      * @param  {string|number|boolean|[]<any>} value
      * @return {BuilderInterface}           
      */
    public where(field: string, value: string|number|boolean|Array<any>): BuilderInterface {
        if(this.whereVals.hasOwnProperty(field)) {
            throw new Error('Field has already been set');
        }
        this.whereVals[field] = value;
        return this;
    }

    /**
     * Main execution of the builder, this generates
     * the required parameters, headers and body for the
     * request to the client
     * @param  {string} method
     * @param  {string} url
     * @param  { [index: string]: any } passedInParams
     * @return {BuilderInterface}
     */
    public async execute(method: HttpMethod, url: string, 
        passedInParams: { [index: string]: any }): Promise<GenericResult|undefined> {

        if(Object.keys(this.whereVals).length > 0) {
            Object.keys(this.whereVals).map((key, index) => {
                passedInParams[key] = this.whereVals[key];
            });

        }

        return super.execute(method, url, passedInParams);

    }

}