import { invalidate, Vector2 } from '@react-three/fiber'
import { RepeatWrapping, TextureLoader } from 'three';

//TODO: https://stackoverflow.com/a/16070490/12404283

interface createTextureMapProps {
  url: string
  repeat?: Vector2 | [number, number],
  rotation?: number
}

const loader = new TextureLoader();

/**
 * creates a texture map with given params and either returns a new material or updates the given material
 */
 const createTextureMap = async ({ url, repeat = [5, 5], rotation = Math.PI / 2 }: createTextureMapProps) => {
  /**
   * dont create if server side
   */
  if (typeof document === 'undefined') return

  const repeatValues = ((repeat as THREE.Vector2).toArray?.() ?? repeat) as [number, number]

  const texture = await loader.loadAsync(url)

  invalidate()

  texture.wrapS = RepeatWrapping
  texture.wrapT = RepeatWrapping

  if (rotation) texture.rotation = rotation

  texture.repeat.set(repeatValues[0], repeatValues[1])

  return texture;
}

export default createTextureMap
