import React from 'react'
import { useTranslation } from 'react-i18next'

import useTextures from '../hooks/useTextures'
import { styled } from '../styles/stitches.config'

const Button = styled('a', {
  background: '#0873e9',
  padding: 8,
  fontSize: 14,
  borderRadius: 8,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  color: '#fff',

  position: 'absolute',
  zIndex: 10,
  right: 6,
  top: 6,

  textDecoration: 'none',

  cursor: 'pointer',
})

const NextButton = () => {
  const { t } = useTranslation();

  const {
    bicolorId,
    buttonId,
    collarTypeId,
    cuffsAndCollarId,
    mainId,
    innerContrastId,
  } = useTextures((state) => ({
    bicolorId: state.bicolorTexture?.id,
    buttonId: state.buttonTexture?.id,
    collarTypeId: state.collarDesign?.id,
    cuffsAndCollarId: state.cuffsTexture?.id,
    innerContrastId: state.innerContrastTexture?.id,
    mainId: state.mainTexture?.id,
  }))

  const isLoggedIn = useTextures(state => state.isLoggedIn);
  const loggedUserType = useTextures(state => state.loggedUserType);
  const toggleLoginModal = useTextures(state => state.toggleLoginModal);

  const handleClick = () => {
    if(!isLoggedIn || !loggedUserType) {
      toggleLoginModal()
    } else {
      if(loggedUserType === 'consultant') {
        window.location.href = `/orders_new_pre.php?collar=${collarTypeId}&buttoncolor=${buttonId}&mainfabric=${mainId}&innerfabric=${innerContrastId}&outerfabric=${cuffsAndCollarId}&bicolorfabric=${bicolorId}`
      }

      if(loggedUserType === 'client') {
        window.location.href = `/shop/shirts.php?collar=${collarTypeId}&buttoncolor=${buttonId}&mainfabric=${mainId}&innerfabric=${innerContrastId}&outerfabric=${cuffsAndCollarId}&bicolorfabric=${bicolorId}`
      }
    }
  }

  return (
    <Button
      onClick={() => handleClick()}
    >
      {t('nav_bar.next')}
    </Button>
  )
}

export default NextButton
