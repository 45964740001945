import React from 'react';

type ButtonsIconProps = React.SVGProps<SVGSVGElement>;

const ButtonIcon = (props: ButtonsIconProps) => {
  return (
    <svg width='349' height='377' viewBox='0 0 349 377' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' stroke='#000' d='M.5 77.5h299v299H.5z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M242.5 241l-34.5-9.5-39.5 14.5-16 45.5 24 41.5 50 8 36.5-35-4-45-16.5-20zm-61 38v14H198v-14h-16.5zm55 0h-16v14h16v-14z'
        fill='#C4C4C4'
      />
      <path
        d='M94.5 112.113c-28.6 7.4-50.1 28.2-59.2 56.9-2 6.5-2.3 9.5-2.3 23s.3 16.5 2.3 23c10.7 34 40.1 57.2 74.8 58.8 15.7.7 31.7-2.7 42.2-8.8 3.1-1.9 3.4-1.9 2.7-.3-4.3 11.3-4.6 30-.5 40.8 6.5 17 19.7 29.9 36.2 35.6 8.7 3 23 3.6 32.2 1.5 26-6.1 44.6-30 44.6-57.1 0-8.7-1.7-16.3-5.1-23.7-2.3-4.8-3.8-6.1-6.3-5.2-2.5 1-2.4 2.7.7 10.4 5.5 13.7 4.8 28.5-2 42.1-12.5 24.9-42.7 34.9-68.2 22.5-25.8-12.5-35.7-43.7-22.4-70.1 3.2-6.2 14.2-17.2 20.9-20.7 18.5-9.9 42.3-7.1 58.2 6.8 5.6 4.9 7.6 5.4 9.3 2.3 1-1.9.5-2.7-4-6.9-6-5.6-16.3-11.2-24.7-13.4-10.4-2.7-25.9-1.8-35.6 1.9-1.5.6-1.2-.5 1.2-5.9 5.4-11.8 7.3-22.3 6.7-36.6-.7-19.4-6-33.6-18.6-49.6-9.6-12.2-27.1-23.3-43.5-27.4-10.8-2.7-29.1-2.7-39.6.1zm34.5 6c28.8 5.1 54.1 30.4 59.4 59.3 1.6 8.6 1.3 22.6-.5 30.9-2 9.3-9 23.6-15.1 31.2-10.1 12.4-26.8 22.6-42.7 26.1-10 2.1-28.1 1.5-37.2-1.4-23.5-7.4-42.3-25.8-49.9-48.6-6.5-19.6-4.1-43.9 6.2-61.6 4.9-8.3 19-22.4 27.3-27.3 15.4-8.9 33.5-11.9 52.5-8.6z'
        fill='#000'
      />
      <path
        d='M97.8 129.013c-18.1 4.8-33.5 17.4-41.8 34.5-8.5 17.6-8.7 38.8-.5 56.1 3.1 6.4 5.3 8.2 7.6 6.3 2-1.6 1.8-3.3-1-8.8-5.5-10.8-7.2-27.5-4.1-39.5 5.2-19.9 22.2-36.9 42.1-42.1 6.9-1.8 20.2-2 27.4-.3 4.3 1 5.3.9 6.6-.4 1.2-1.2 1.3-2 .5-3.5-2.3-4.4-23.8-5.7-36.8-2.3zM142.1 135.813c-.9 1-1.6 2.1-1.6 2.5 0 .3 3.4 3.1 7.5 6.1 12.1 9 19.3 19.8 23.1 34.6 2.2 8.6 1.5 23-1.6 31.7-5.8 16.5-19.9 30.7-36.3 36.3-8 2.7-21.6 3.7-29.9 2.1-8.4-1.6-21.1-7.9-27.5-13.6-5.8-5.1-8.6-5.5-9.1-1.4-.2 2 .8 3.4 4.5 6.6 11.9 10 24.6 15.1 39.8 16 31.6 1.8 60.6-20.5 67.1-51.7 3.2-15.4.1-33.6-8.3-47.3-4.5-7.4-14.5-17.4-21-20.9-4.8-2.7-5.1-2.7-6.7-1z'
        fill='#000'
      />
      <path
        d='M88.8 163.013c-4.1 2.4-5.3 4.8-5.3 10.2 0 5 1.9 8.2 6 10.3 4.9 2.5 9.6 1.9 13.5-1.8 10.9-10-1.5-26.5-14.2-18.7zm10.9 7.5c1.7 3.8-.3 7.5-4.2 7.5-2.1 0-3.4-.8-4.6-2.5-1.5-2.3-1.5-2.7 0-5 1.2-1.7 2.5-2.5 4.6-2.5 2.3 0 3.3.6 4.2 2.5zM127.3 162.613c-7.7 4.1-7.5 16.9.2 20.9 6 3.1 13.7 1 16.5-4.5 5.7-11-5.8-22.3-16.7-16.4zm10 6.6c.7.7 1.2 2.4 1.2 3.8s-.5 3.1-1.2 3.8c-.7.7-2.4 1.2-3.8 1.2s-3.1-.5-3.8-1.2c-1.5-1.5-1.5-6.1 0-7.6.7-.7 2.4-1.2 3.8-1.2s3.1.5 3.8 1.2zM86.9 202.413c-3 3-3.4 4-3.4 8.4 0 4.1.5 5.5 2.8 8 1.6 1.7 4.1 3.4 5.6 3.8 7.1 1.7 14.3-3.1 15.3-10.3 1-7.7-4.3-13.3-12.8-13.3-3.3 0-4.7.6-7.5 3.4zm12.4 4.9c1.7 1.3 1.5 5.2-.4 7.1-3.8 3.9-11-.6-8.5-5.3 2.1-3.8 5.4-4.5 8.9-1.8zM126.3 201.313c-5.9 5-6.5 11.8-1.5 17.2 4 4.5 8.9 5.5 14.1 3 4.4-2.1 6.6-5.9 6.6-11.6 0-3.3-.6-4.7-3.4-7.5-2.9-2.9-4.1-3.4-8.2-3.4-3.6 0-5.6.6-7.6 2.3zm12 9.5c.2 2.3-.2 3.4-1.8 4.2-3.1 1.7-6.9.4-7.6-2.5-.8-3.3.1-5.4 2.8-6.4 3.2-1.3 6.2.9 6.6 4.7zM185 273.913c-8.5 3.8-10.4 15.6-3.4 21.5 5.6 4.7 12.6 4.7 17.2-.2 5.7-6 5.7-13-.2-18.6-3.2-3-9.9-4.3-13.6-2.7zm8.9 8c2.5 2.7 1.7 7.2-1.5 9-2.4 1.3-2.9 1.3-5.3-.2-3.4-2.2-3.6-6.9-.4-9.1 3-2.1 5.1-2 7.2.3zM221.9 274.913c-3.3 2-6.4 7.5-6.4 11.3 0 6.1 6.9 12.8 13.3 12.8 6.6 0 12.7-6.2 12.7-13 0-10-11.1-16.3-19.6-11.1zm10.4 6.7c2.8 1.9 2.9 5.7.2 8.4-2.6 2.6-4.6 2.5-7.5-.5-3-2.9-3.1-3.5-.4-6.9 2.3-3 4.5-3.3 7.7-1z'
        fill='#000'
      />
    </svg>
  );
};

export default ButtonIcon;
