import React from 'react';

import useTextures from '../../../hooks/useTextures';

import KentClassicModel from './KentClassic';
import KentModernModel from './KentModern';
import BristolModel from './Bristol';
import StehkragenModel from './Stehkragen';
import HaifischSmallModel from './HaifischSmall';
import HaifischMediumModel from './HaifischMedium';
import HaifischLargeModel from './HaifischLarge';
import ButtonDownModel from './ButtonDown';
import UnderButtonDownModel from './UnderButtonDown';
import UnderButtonDownLargeModel from './UnderButtonDownLarge';
import ModernButtonDownModel from './ModernButtonDown';

const Collar = () => {
  const collarDesignId = useTextures((store) => store.collarDesign?.id);

  switch (collarDesignId) {
    case 1:
      return <KentClassicModel />;
    case 2:
      return <KentModernModel />;
    case 3:
      return <BristolModel />;
    case 4:
      return <StehkragenModel />;
    case 5:
      return <HaifischSmallModel />;
    // case 6:
    //   return <HaifischMediumModel />;
    case 7:
      return <HaifischLargeModel />;
    // case 8:
    //   return <KentClassicModel />;
    case 9:
      return <ButtonDownModel />;
    case 10:
      return <UnderButtonDownModel />;
    case 11:
      return <UnderButtonDownLargeModel />;
    // case 12:
    //   return <KentClassicModel />;
    case 13:
      return <ModernButtonDownModel />;

    default:
      return null;
  }
};

export default Collar;
