export let translations = {
  // put translations in DE here
  '[[Cotton]]': 'Baumwolle',
  '[[Polyester]]': 'Polyester',
  '[[Spandex]]': 'Elasthan',
  '[[Other]]': 'Anderes',
  '[[Upcycled Polyester]]': 'Upcycling-Polyester',
  '[[Linen]]': 'Leinen',
  '[[Bamboo]]': 'Bamboo',
  '[[Tencel]]': 'Tencel',
  '[[Rayon]]': 'Viskose',
  '[[Nylon]]': 'Nylon',
  available: 'Verfügbar',
  soon_sold_out_coming_back: 'Bald ausverkauft',
  soon_sold_out_not_coming_back: 'Bald ausverkauft',
  sold_out_coming_back: 'Ausverkauft',
  sold_out_not_coming_back: 'Ausverkauft',
  inactive: 'Inaktiv',
  '[[stretch_fabric]]': 'Stretchstoff',
  '[[jersey_fabric]]': 'Jersey',
  no_next_delivery: 'Aktuell keine Lieferung',
  next_delivery: 'Nächste Lieferung',
  fabric_available: 'Verfügbar',
  fabric_bigger_amounts_available: 'Größere Menge verfügbar',
  fabric_soon_sold_out: 'Bald ausverkauft',
  fabric_sold_out: 'Ausverkauft',
  beginningOf: 'Anfang ',
  middleOf: 'Mitte ',
  endOf: 'Ende ',
  January: 'Januar',
  February: 'Februar',
  March: 'März',
  April: 'April',
  May: 'Mai',
  June: 'Juni',
  July: 'Juli',
  August: 'August',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'Dezember',
  availableNote: 'Dauerhaft verfügbar',
  comingBackNote: 'Aktuell nicht verfügbar, kommt bald wieder.',
  notComingBackNote: 'Nicht mehr verfügbar, kommt nicht wieder.'
}
