import Base from '../base'
import Dictionary from '../collection';
import { GenericResult, CollectionResultType, ResultType } from '../utils';
import { Fabric } from '../instance';
import { FabricBuilder } from '../builder';


export type FabricSearchFilters = {
    name: string | null,
    statuses: string[],
    familyIds: number[],
    bestSellingPeriod: number | null,
    releaseDatePeriod: number | null,
    fibers: string[],
    patterns: string[],
    withInventory: boolean
};

export class FabricTrait extends Base {

    /**
     * Returns all the fabrics in our database according to the status
     * criteria provided
     * @param  {string[]} select
     * @param  {string[]} statuses
     * @param  {string} orderField
     * @param  {'asc' | 'desc'} orderDirection    
     * @param  {boolean = false} excludeSeasonalFabrics
     * @param  {boolean = false} cottonOnly
     * @param  {boolean = false} excludeSpecials   
     * @param  {boolean = false} removeLadyFabrics 
     * @return {Promise}
     */
    public async getAllFabrics(select: string[], statuses: string[], orderField: string, 
    	orderDirection: 'asc' | 'desc', excludeSeasonalFabrics: boolean = true, 
    	cottonOnly: boolean = false, excludeSpecials: boolean = true, 
    	removeLadyFabrics: boolean = false, withInventory: boolean = false): Promise<Dictionary<Fabric>> {

    	let builder = <FabricBuilder>this.getBuilder('FabricBuilder')
    		.select(select)
    		.whereEquals('status', statuses)
    		.addRelationship('fabricCompositionLabel')
    		.addRelationship('images')
    		.sortBy(orderField, orderDirection);

    	if(excludeSeasonalFabrics) {
    		builder.excludeSeasonalFabrics();
    	}

    	if(cottonOnly) {
    		builder.includeCottonOnlyFabrics();
    	}

    	if(removeLadyFabrics) {
    		builder.removeLadyFabrics();
    	}

    	if(withInventory) {
            builder.withInventory();
        }

        let collection = new Dictionary<Fabric>();
        const results = await builder.execute("get", 'fabrics', {});
        if(!results) {
            return collection;
        } 

        const resArr = <CollectionResultType>results.getResults();

        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <Fabric>Fabric.createFromRaw(result, 'Fabric'));
            count++;
        })

        if(excludeSpecials) {
            collection = this._excludeSpecialFabrics(collection);
        }

        return collection;
    }

    /**
     * Returns a list of Fabric ID => Names that can be used 
     * to populate dropdowns
     * @param  {string[]}   statuses
     * @param  {string ='name'} orderField            
     * @param  {'asc'|'desc' = 'asc'} orderDirection
     * @param  {boolean = false} excludeSeasonalFabrics
     * @param  {boolean = false} cottonOnly            
     * @param  {boolean = true} excludeSpecials       
     * @param  {boolean = false} removeLadyFabrics     
     * @return {Promise}            
     */
    public async getFabricsForDropdown(statuses: string[], orderField: string = 'name', 
        orderDirection: 'asc' | 'desc' = 'asc', excludeSeasonalFabrics: boolean = true, 
        cottonOnly: boolean = false, excludeSpecials: boolean = true, 
        removeLadyFabrics: boolean = false): Promise<Dictionary<Fabric>> {

        let builder = <FabricBuilder>this.getBuilder('FabricBuilder')
            .select(['id', 'name', 'status', 'type', 'is_shirt_fabric'])
            .whereEquals('status', statuses)
            .sortBy(orderField, orderDirection);

        if(excludeSeasonalFabrics) {
            builder.excludeSeasonalFabrics();
        }

        if(cottonOnly) {
            builder.includeCottonOnlyFabrics();
        }

        if(removeLadyFabrics) {
            builder.removeLadyFabrics();
        }

        let collection = new Dictionary<Fabric>();
        const results = await builder.execute("get", 'fabrics', {});
        if(!results) {
            return collection;
        } 

        const resArr = <CollectionResultType>results.getResults();

        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <Fabric>Fabric.createFromRaw(result, 'Fabric'));
            count++;
        })

        if(excludeSpecials) {
            collection = this._excludeSpecialFabrics(collection);
        }

        return collection;
    }

    /**
     * Same as the function above but this also adds the images relationship
     * to the result
     * @param  {string[]}   statuses
     * @param  {string ='name'} orderField            
     * @param  {'asc'|'desc' = 'asc'} orderDirection
     * @param  {boolean = false} excludeSeasonalFabrics
     * @param  {boolean = false} cottonOnly            
     * @param  {boolean = true} excludeSpecials       
     * @param  {boolean = false} removeLadyFabrics     
     * @return {Promise}            
     */
    public async getFabricsListWithImages(statuses: string[], orderField: string = 'name', 
        orderDirection: 'asc' | 'desc' = 'asc', excludeSeasonalFabrics: boolean = true, 
        cottonOnly: boolean = false, excludeSpecials: boolean = true, 
        removeLadyFabrics: boolean = false): Promise<Dictionary<Fabric>> {

        let builder = <FabricBuilder>this.getBuilder('FabricBuilder')
            .select(['id', 'name', 'status', 'type', 'is_shirt_fabric'])
            .whereEquals('status', statuses)
            .addRelationship('images')
            .sortBy(orderField, orderDirection);

        if(excludeSeasonalFabrics) {
            builder.excludeSeasonalFabrics();
        }

        if(cottonOnly) {
            builder.includeCottonOnlyFabrics();
        }

        if(removeLadyFabrics) {
            builder.removeLadyFabrics();
        }

        let collection = new Dictionary<Fabric>();
        const results = await builder.execute("get", 'fabrics', {});
        if(!results) {
            return collection;
        } 

        const resArr = <CollectionResultType>results.getResults();

        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <Fabric>Fabric.createFromRaw(result, 'Fabric'));
            count++;
        })

        if(excludeSpecials) {
            collection = this._excludeSpecialFabrics(collection);
        }

        return collection;

    }

    /**
     * This method get a unique fabric from the list of fabric returned by the API
     * @param {number} fabricId
     * 
     */
    public async getFabric(fabricId: number): Promise<Fabric|null> {

        let builder = <FabricBuilder>this.getBuilder('FabricBuilder')
            .select(['*'])
            .whereEquals('id', fabricId)
            .addCustomField('inventory')
            .addRelationship('fabricCompositionLabel')
            .addRelationship('images');

        const results = await builder.execute("get", 'fabrics', {});
        if(!results) {
            return null;
        } 

        const resArr = <CollectionResultType>results.getResults();

        return <Fabric>Fabric.createFromRaw(resArr.data.collection[0], 'Fabric');

    }

    /**
     * Removes the special fabrics (hard coded below) from the
     * collection
     * @param  {Dictionary<Fabric>} coll
     * @return {Dictionary<Fabric>}
     */
    private _excludeSpecialFabrics(coll: Dictionary<Fabric>): Dictionary<Fabric> {

        const specialFabrics = [
            10082, 10118, 10099, 10100, 10156, 10161, 
            10054, 10057, 10058, 10149, 10154, 10157, 
            10138, 10142, 10153, 30001, 30002, 30009, 
            10160, 10008, 10104, 10135, 10009, 10080, 
            10150, 10059, 10102, 10106, 10081, 10085, 
            10097, 10031, 10078, 10117, 10111, 10119, 
            10132, 10105, 10131, 10077
        ];

        return coll.filter((fabric) => specialFabrics.indexOf(fabric.getId()) === -1);

    }

    /**
     * Get the data required to display the advanced filters on the fabrics page
     * 
     * @return {Promise<ResultType>}
     */ 
    public async getFabricPageFilterData(): Promise<ResultType> {
        let builder = <FabricBuilder>this.getBuilder('FabricBuilder');
        const results = await builder.execute("get", 'fabrics/page-filters-data', {});

        if(!results) {
            throw new Error('Error while fetching fabric filter data');
        }
        return <ResultType>results.getResults();
    }

    /**
     * Return the list of fabrics that matches the user-provided filters.
     */ 
    public async getAllFilteredFabrics(filters: FabricSearchFilters): Promise<Dictionary<Fabric>> {


        let builder = <FabricBuilder>this.getBuilder('FabricBuilder');

        let collection = new Dictionary<Fabric>();
        const results = await builder.execute("post", 'fabrics/filtered', filters);
        if(!results) {
            return collection;
        }

        const resArr = <CollectionResultType>results.getResults();

        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <Fabric>Fabric.createFromRaw(result, 'Fabric'));
            count++;
        })

        return collection;
    }
}