import React from 'react';

import useTextures from '../../../hooks/useTextures';
import ButtonIcon from '../../Icons/ButtonIcon';
import ConfiguratorOpenButton from './ConfiguratorOpenButton';

const OpenButtonConfiguratorButton = () => {
  const setConfiguratorTab = useTextures((state) => state.setConfiguratorTab);

  const handleClick = () => setConfiguratorTab('button');

  return (
    <ConfiguratorOpenButton onClick={handleClick}>
      <ButtonIcon />
    </ConfiguratorOpenButton>
  );
};

export default OpenButtonConfiguratorButton;
