import { useGLTF } from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import useTextures from '../../../hooks/useTextures';

type GLTFResult = GLTF & {
  nodes: {
    knopf: THREE.Mesh;
    kragen_außen: THREE.Mesh;
    kragen_innen: THREE.Mesh;
  };
};

const Stehkragen = ({ ...props }: JSX.IntrinsicElements['group']) => {
  const { nodes } = useGLTF('./build/models/stehkragen.glb') as unknown as GLTFResult;

  const innerCollarTexture = useTextures((store) => store.innerCollarTexture?.texture);
  const buttonColor = useTextures((store) => store.buttonTexture?.hex_code);
  const collarTexture = useTextures((store) => store.collarTexture?.texture);

  return (
    <group {...props} dispose={null} rotation={[-Math.PI / 2, 0, 0]}>
      <mesh geometry={nodes.knopf.geometry}>
        <meshStandardMaterial color={buttonColor} />
      </mesh>

      <mesh geometry={nodes.kragen_außen.geometry}>
        <meshStandardMaterial map={collarTexture} />
      </mesh>

      <mesh geometry={nodes.kragen_innen.geometry}>
        <meshStandardMaterial map={innerCollarTexture} />
      </mesh>
    </group>
  );
};

useGLTF.preload('./build/models/stehkragen.glb');

export default Stehkragen;
