import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

export type ShirtImageSize = 'main' | 'mobile' | 'thumbnail'; 

export class ShirtImage extends InstanceAbstract implements InstanceInterface {

	/**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

	/**
	 * @type {number}
	 */ 
	private variant: number = 0;

	/**
	 * @type {ShirtImageSize}
	 */ 
	private size: ShirtImageSize = 'main';

	/**
	 * @type {string}
	 */ 
	private image_url: string = '';	


	/**
	 * @return {number}
	 */ 
	public getVariant(): number {
		return this.variant;
	}

	/**
	 * @param {number}
	 * @return {ShirtImage}
	 */ 
	public setVariant(variant: number): ShirtImage {
		this.variant = variant;
		return this;
	}

	/**
	 * @return {ShirtImageSize}
	 */ 
	public getSize(): ShirtImageSize {
		return this.size;
	}

	/**
	 * @param {ShirtImageSize} size
	 * @return {ShirtImage}
	 */ 
	public setSize(size: ShirtImageSize): ShirtImage {
		this.size = size;
		return this;
	}

	/**
	 * @return {string}
	 */ 
	public getImageUrl(): string {
		return this.image_url;
	}

	/**
	 * @param {string} image_url
	 * @return {ShirtImage}
	 */ 
	public setImageUrl(image_url: string): ShirtImage {
		this.image_url = image_url;
		return this;
	}
}