import BuilderInterface from './BuilderInterface';
import { Builder } from './Builder';
import Base from '../base';

export class FabricBuilder extends Builder implements BuilderInterface {

	/**
     * Excludes the seasonal fabric by only picking
     * everything but seasonal as fabric type
     * @return <BuilderInterface>
     */
    public excludeSeasonalFabrics(): BuilderInterface {
    	this.whereEquals('type', ['Main', 'Contrast', 'Other']);
        return this;
    }

    /**
     * Filters the fabrics so only 100% cotton fabrics
     * are returned
     * @return <BuilderInterface>
     */
    public includeCottonOnlyFabrics(): BuilderInterface {
    	this.whereEquals('fiber_1', 'Cotton');
    	this.whereEquals('fiber_1_percentage', 100);
    	return this;
    }

    /**
     * Only return the suitable for male fabrics
     * @return <BuilderInterface>
     */
    public removeLadyFabrics(): BuilderInterface {
    	this.whereEquals('gender_suitable_for', 'male');
    	return this;
    }

    /**
     * Only return the suitable for male fabrics
     * @return <BuilderInterface>
     */
    public withInventory(): BuilderInterface {
        this.addCustomField('inventory');
        return this;
    }

}