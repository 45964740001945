import create from 'zustand';

interface useZoomedToCollarProps {
  zoomedToCollar: boolean;
  setZoomedToCollar: (zoom: boolean) => void;
}

const useZoomedToCollar = create<useZoomedToCollarProps>((set) => ({
  zoomedToCollar: false,
  setZoomedToCollar: (zoom) => set({ zoomedToCollar: zoom }),
}));

export default useZoomedToCollar;
