import { useClickOutside } from '@mantine/hooks'
import React from 'react'

import useTextures, { FabricImage } from '../../hooks/useTextures'
import collarList from '../../lists/collarList'
import { styled } from '../../styles/stitches.config'
import ScrollArea from '../ScrollArea'
import CloseModalButton from './Buttons/CloseModalButton'
import ChangeButtonColor from './ChangeButtonColor'
import ChangeCollar from './ChangeCollar'
import ChangeTexture from './ChangeTexture'

const Container = styled('div', {
  background: '#fff',
  boxShadow:
    'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
  position: 'absolute',
  top: 50,
  left: 0,
  minWidth: 500,
  width: '100%',
  maxWidth: 'calc(100vw / 2.1)',
  zIndex: 999,

  '@tablet': {
    minWidth: '100%',
    width: '100vw',
  },
})

const ScrollContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-evenly',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: 4,
})

const TextureSelectModal = () => {
  const { configuratorTab, setConfiguratorTab } = useTextures((state) => ({
    configuratorTab: state.configuratorTab,
    setConfiguratorTab: state.setConfiguratorTab,
  }))

  const buttonsList = useTextures((state) => state.buttonsList)
  const fabricsList = useTextures((state) => state.fabricsList)

  const filteredFabrics = (['cuffsAndCollar', 'innerContrast'].indexOf(configuratorTab!) === -1)
    ? fabricsList?.filter((fabric: any) => (window as any).contrastOnlyFabrics?.indexOf(fabric.id) === -1)
    : fabricsList;

  const ref = useClickOutside(() => setConfiguratorTab('closed'))

  if (configuratorTab === 'closed') return null

  return (
    <Container ref={ref}>
      <CloseModalButton />

      <ScrollArea height={'calc(100vh - 50px)'}>
        <ScrollContainer>
          {[
            'all',
            'main',
            'bicolor',
            'cuffsAndCollar',
            'innerContrast',
          ].includes(configuratorTab ?? '') &&
            Array.isArray(filteredFabrics) &&
            filteredFabrics?.sort((a, b) => a.name > b.name ? 1 : -1).map((fabric) => {
              const thumbnail =
                fabric.images?.find(
                  (image: FabricImage) => image.type === 'konfigurator_swatch'
                ) ?? {}
              const image =
                fabric.images?.find(
                  (image: FabricImage) => image.type === 'konfigurator_texture'
                ) ??
                null
              return image ? (
                <ChangeTexture
                  key={fabric.id}
                  id={fabric.id}
                  previewUrl={thumbnail.image_url}
                  name={fabric.name}
                  url={image.image_url}
                  type={configuratorTab ?? 'all'}
                />
              ) : null
            })}

          {configuratorTab === 'collarDesign' &&
            collarList.sort((a: any, b: any) => a.label > b.label ? 1 : -1).map((collar) => (
              <ChangeCollar key={collar.id} {...collar} />
            ))}

          {configuratorTab === 'button' &&
            Array.isArray(buttonsList) &&
            buttonsList?.sort((a, b) => a.title_en > b.title_en ? 1 : -1).map((button) => (
              <ChangeButtonColor key={button.id} {...button} />
            ))}
        </ScrollContainer>
      </ScrollArea>
    </Container>
  )
}

export default TextureSelectModal
