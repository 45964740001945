import Base from '../base';

import Dictionary, { PaginationDetails } from '../collection';
import { FabricBook, FabricBookCategory } from '../instance';
import { Builder } from '../builder';
import { GenericResult, CollectionResultType, ResultType } from '../utils';


export class FabricBookCategoryTrait extends Base {

	/**
	 * Get the list of categories
	 * 
	 * @param {string} orderField
	 * @param {string} orderDirection
	 * @param {number} page
	 * @param {number} perPage
	 */ 
	async getFabricBookCategories(orderField: string, orderDirection:  'asc' | 'desc', page: number, perPage: number): Promise<Dictionary<FabricBookCategory>> {

		const collection = new Dictionary<FabricBookCategory>();

		let builder = <Builder>this.getBuilder()
						.sortBy(orderField, orderDirection)
						.page(page).resultsPerPage(perPage);

		const results = await builder.execute("get", 'fabrics/book-category', {});

		if(!results) {
            return collection;
        } 

        const resArr = <CollectionResultType>results.getResults();
        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <FabricBookCategory>FabricBookCategory.createFromRaw(result, 'FabricBookCategory'));
            count++;
        })
		collection.setPagination(<PaginationDetails>resArr.data.pagination);


		return collection;

	}

	/**
	 * Get the list of books in a book category
	 * 
	 * @param {number} fabricBookId
	 */ 
	async getFabricBookCategoryBooks(categoryId: number): Promise<Array<FabricBook>> {

		const collection = new Array<FabricBook>();

		let builder = <Builder>this.getBuilder();


		const results = await builder.execute("get", 'fabrics/book-category/'+ categoryId +'/books', {
            my_books : true
		});

		if(!results) {
            return collection;
        }

        const books =  <Array<{}>>(<ResultType>results.getResults()).data;

        books.forEach(result => {
            collection.push(<FabricBook>FabricBook.createFromRaw(result, 'FabricBook'));
        });

		return collection;

	}

}