import BuilderInterface from './BuilderInterface';
import Base from '../base';
import { FabricBuilder} from './FabricBuilder'
import { HttpMethod, ApiResponse} from '../types';
import { GenericResult, ResultType, applyMixins } from '../utils';

type BuilderParams = {
	columns?: string,
	filters?: string,
	search?: string,
	includes?: string,
	sort?: string,
	relationships?: string,
	page?: number,
	per_page?: number,
	custom_fields?: string
}

export class Builder implements BuilderInterface {

    /**
      * the HTTP client to make requests with
      * @type {[type]}
      */
    private client: Base;

    /**
     * Columns to include in the API results
     * @type {string[]}
     */
    private columns: string[] = [];

    /**
     * Whether or not to filter the results
     * @type {boolean}
     */
    private filterResults: boolean = false;

    /**
     * An array of columns to search in if the results are being
     * filtered
     * @type {string[]}
     */
    private filterFields: string[] = [];

	/**
	 * An array of custom columns to include the search results
	 * filtered
	 * @type {string[]}
	 */
	private customFields: string[] = [];

    /**
     * the filter term if the results are being filtered
     * @type {string}
     */
    private filterTerm: string = '';

    /**
     * a key-value [field=>value] list with fields and 
     * what values will be directly included on that field
     * @type { [index: string]: any }
     */
    private whereEqualsVals: { [index: string]: any } = {};

    /**
     * [field=>direction(asc/desc)] to sort the results by
     * @type { [index: string]: 'asc' | 'desc' }
     */
    private sort: { [index: string]: 'asc' | 'desc' } = {};

    /**
     * whether or not to paginate the results
     * @type {boolean}
     */
    private shouldPaginate: boolean = false;

    /**
     * page number for pagination
     * @type {number}
     */
    private pageVal: number = 0;

    /**
     * number of results to return per page
     * @type {number}
     */
    private resultsPerPageVal: number = 20;

    /**
     * relationships to include with base model
     * @type {string[]}
     */
    private relationships: string[] = [];

    /**
	 * Class Constructor
	 * @param {Base} base [description]
	 */
	constructor(base: Base) {
        this.client = base;
    }

	/**
     * Set an array of columns to include in the 
     * result from the API
     * @param  {string[]} columns
     * @return {BuilderInterface}        
     */
    public select(columns: string[]): BuilderInterface {
    	this.columns = columns;
    	return this;
    }

    /**
     * Sets a term to filter the results by and the columns
     * in which the occurence of the term is searched in
     * @param  {string} term    
     * @param  {string[]} columns 
     * @return {BuilderInterface}         
     */
    public filter(term: string, columns: string[]): BuilderInterface {
    	if(columns.length <= 0) {
    		throw new Error('Need at least one field to filter by');
    	}

    	this.filterResults = true;
    	this.filterTerm    = term;
    	this.filterFields  = columns;
    	return this;
    }

	/**
	 * Add a custom field to be fetched with the standard fields
	 * @param name
	 */
	public addCustomField(name: string): BuilderInterface {
		this.customFields.push(name);
		return this;
	}

    /**
     * Sets a field to sort by and it's direction
     * @param  {string} field
     * @param  {string} direction
     * @return {BuilderInterface}
     */
    public sortBy(field: string, direction: "asc" | "desc"): BuilderInterface {
    	this.sort[field] = direction;
    	return this;
    }

	/**
	 * Clear the sort by field.
	 * This is useful when the same client is used by many endpoint
	 * and we wish to clear the previously added sorting
	 */
	public clearSortBy(): BuilderInterface {
		this.sort = {};
		return this;
	}
   
    /**
      * Sets a field and value (can be array) that the API will
      * search specifically on.
      * @param  {string} field
      * @param  {string|number|boolean|[]<any>} value
      * @return {BuilderInterface}           
      */
    public whereEquals(field: string, value: string|number|boolean|Array<any>): BuilderInterface {
    	if(this.whereEqualsVals.hasOwnProperty(field)) {
    		throw new Error('Field has already been set');
    	}
    	this.whereEqualsVals[field] = value;
		return this;
    }

    /**
     * Sets the page to retireve results from
     * @param  {number} page
     * @return {BuilderInterface}     
     */
    public page(page: number): BuilderInterface {
    	this.shouldPaginate = true;
        this.pageVal = page;
        return this;
    }

    /**
     * Sets the number of results to be returned
     * per page
     * @param  {number} results
     * @return {BuilderInterface}
     */
    public resultsPerPage(results: number): BuilderInterface {
        this.shouldPaginate = true;
        this.resultsPerPageVal = results;
        if(this.pageVal <= 0) {
        	this.pageVal = 1;
        }
    	return this;
    }

    /**
     * Adds a relationship to the query
     * @param  {string} relationship
     * @return {BuilderInterface}
     */
    public addRelationship(relationship: string): BuilderInterface {
    	this.relationships.push(relationship);
    	return this;
    }
    
    /**
     * Main execution of the builder, this generates
     * the required parameters, headers and body for the
     * request to the client
     * @param  {string} method
     * @param  {string} url
     * @param  { [index: string]: any } passedInParams
     * @param  {boolean} isShopRequest If true, the request will be sent to the Shop API endpoint.
     * @param  {boolean} shouldCache If true, the response will be cached
     * @return {BuilderInterface}
     */
    public async execute(method: HttpMethod, url: string, 
    	passedInParams: { [index: string]: any }, isShopRequest: boolean = false, shouldCache: boolean = false): Promise<GenericResult|undefined> {

    	if(this.client === null) {
    		throw new Error('HTTP Client needs to be set');
    	}

    	const params:BuilderParams = {};

    	Object.assign(params, passedInParams);

    	if(this.columns.length > 0) {
    		params.columns = this.columns.join(',');
    	}

    	if(this.filterResults) {
    		params.filters = this.filterFields.join(',');
    		params.search  = this.filterTerm;
    	}

    	if(this.customFields.length > 0) {
    		params.custom_fields = this.customFields.join(',');
		}

    	if(Object.keys(this.whereEqualsVals).length > 0) {
    		params.includes = '';
    		Object.keys(this.whereEqualsVals).map((key, index) => {
    			if(Array.isArray(this.whereEqualsVals[key])) {
    				params.includes += `${key}:${this.whereEqualsVals[key].join(',')}|`;
    			} else {
    				params.includes += `${key}:${this.whereEqualsVals[key]}|`;
    			}
    		});
    		params.includes.replace(/|(\s+)?$/, ''); 
    	}

    	if(Object.keys(this.sort).length > 0) {
    		params.sort = '';
    		Object.keys(this.sort).map((key, index) => {
    			params.sort += `${key}:${this.sort[key]},`;
    		});
    		params.sort.replace(/,(\s+)?$/, ''); 
    	}

    	if(this.shouldPaginate) {
    		params.page = this.pageVal;
    		params.per_page = this.resultsPerPageVal;
    	}

    	if(this.relationships.length > 0) {
    		params.relationships = this.relationships.join(',');
    	}

    	return await this.client.request(method, url, params, isShopRequest, shouldCache);

    }

}
