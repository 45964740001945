import React from 'react';

import useTextures, { configuratorTab } from '../../../hooks/useTextures';
import ShirtIcon, { ShirtIconProps } from '../../Icons/ShirtIcon';
import ConfiguratorOpenButton from './ConfiguratorOpenButton';

interface OpenShirtConfiguratorButtonProps extends ShirtIconProps {
  tab: configuratorTab;
}

const OpenShirtConfiguratorButton = ({ tab, ...rest }: OpenShirtConfiguratorButtonProps) => {
  const setConfiguratorTab = useTextures((state) => state.setConfiguratorTab);

  const handleClick = () => setConfiguratorTab(tab);

  return (
    <ConfiguratorOpenButton onClick={handleClick}>
      <ShirtIcon {...rest} />
    </ConfiguratorOpenButton>
  );
};

export default OpenShirtConfiguratorButton;
