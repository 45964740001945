import { isEqual } from 'lodash-es'
import create from 'zustand'
import axios from 'axios'
import queryString from 'query-string'
import createTextureMap from '../helpers/createTextureMap'
import collarList from '../lists/collarList'
import { collarListProps } from '../lists/collarList'
import { texturesListProps } from '../lists/texturesList'
import { buttonsListProps } from '../lists/buttonList'
import { Vector2 } from '@react-three/fiber'
import client from '../utils/client'

interface shirtTextureProps extends texturesListProps {
  texture?: THREE.Texture;
  images?: Array<any>;
}

interface buttonTextureProps extends buttonsListProps {
  texture?: THREE.Texture;
}

export type configuratorTab =
  | 'all'
  | 'main'
  | 'bicolor'
  | 'cuffsAndCollar'
  | 'innerContrast'
  | 'collarDesign'
  | 'button'
  | 'closed'

export interface FabricEntry {
  id: Number | string
  name: string
}

export interface FabricImage {
  type: string
  variant: string
}

interface useTexturesProps {
  buttonsList: shirtTextureProps | null
  fabricsList: Array<shirtTextureProps> | null

  bicolorTexture: shirtTextureProps | null
  cuffsTexture: shirtTextureProps | null

  collarTexture: shirtTextureProps | null
  innerCollarTexture: shirtTextureProps | null

  mainTexture: shirtTextureProps | null
  innerContrastTexture: shirtTextureProps | null
  buttonTexture: buttonTextureProps | null

  configuratorTab: configuratorTab | null

  collarDesign: collarListProps | null

  isLoggedIn: Boolean
  logginIn: Boolean
  loggedUserType: String | null
  showLoginErrorMsg: Boolean
  loginErrorText: string

  preConfigured: any,

  displayLoginModal: Boolean

  handleLogin: (email: string, password: string, userType: string) => Promise<any>
  getFabrics: () => Promise<any>
  toggleLoginModal: () => void
  setConfiguratorTab: (tab: configuratorTab) => void
  setShareParams: () => void

  setTexture: (newTexture: shirtTextureProps) => void
  setButtonTexture: (newTexture: buttonTextureProps) => void

  setCollarDesign: (newCollarDesign: collarListProps) => void
}

interface newTextureOptions {
  repeat?: Vector2
  rotation?: number
}

const createNewTexture = async (
  newTexture: shirtTextureProps | null,
  options?: newTextureOptions
) => {
  if (!newTexture) {
    return newTexture
  }

  return {
    ...newTexture,
    texture: await createTextureMap({
      url: newTexture.url,
      repeat: options?.repeat,
      rotation: options?.rotation
    }),
  }
}

/**
 * hook so safe and retrieve all the textures
 */
const useTextures = create<useTexturesProps>((set, get) => ({
  /**
   * initialize all textures (maybe with random number ?)
   */

  buttonsList: null,
  fabricsList: null,
  bicolorTexture: null,
  cuffsTexture: null,
  collarTexture: null,
  innerCollarTexture: null,
  mainTexture: null,
  innerContrastTexture: null,
  buttonTexture: null,
  collarDesign: null,
  isLoggedIn: (window as any).isLoggedIn,
  loggedUserType: (window as any).loggedUserType ?? null,
  displayLoginModal: false,
  configuratorTab: 'closed',
  showLoginErrorMsg: false,
  loginErrorText: "",
  logginIn: false,
  preConfigured: queryString.parse(window.location.search),

  setShareParams: () => {
    const bicolorId = get().bicolorTexture?.id
    const buttonId = get().buttonTexture?.id
    const collarTypeId = get().collarDesign?.id
    const cuffsAndCollarId = get().cuffsTexture?.id
    const innerContrastId = get().innerContrastTexture?.id
    const mainId = get().mainTexture?.id

    const konfiguration = {
      collarTypeId,
      buttonId,
      mainId,
      innerContrastId,
      cuffsAndCollarId,
      bicolorId,
    }

    const stringified = queryString.stringify(konfiguration)

    window.history.replaceState(null, "", "?" + stringified);
  },

  handleLogin: async (email: string, password: string, userType: string) => {
    try {

      const payload = new FormData();
      payload.append("email", email);
      payload.append("password", password);
      payload.append("userType", userType);

      const { data } = await axios.post("/login_ajax.php", payload)

      set({
        showLoginErrorMsg: false,
        displayLoginModal: false,
        isLoggedIn: true,
        logginIn: true,
        loggedUserType: userType
      })

      const bicolorId = get().bicolorTexture?.id
      const buttonId = get().buttonTexture?.id
      const collarTypeId = get().collarDesign?.id
      const cuffsAndCollarId = get().cuffsTexture?.id
      const innerContrastId = get().innerContrastTexture?.id
      const mainId = get().mainTexture?.id
  
      if(userType === 'consultant') {
        window.location.href = `/orders_new_pre.php?collar=${collarTypeId}&buttoncolor=${buttonId}&mainfabric=${mainId}&innerfabric=${innerContrastId}&outerfabric=${cuffsAndCollarId}&bicolorfabric=${bicolorId}`
      }

      if(userType === 'client') {
        window.location.href = `/shop/shirts.php?collar=${collarTypeId}&buttoncolor=${buttonId}&mainfabric=${mainId}&innerfabric=${innerContrastId}&outerfabric=${cuffsAndCollarId}&bicolorfabric=${bicolorId}`
      }

      return data
    } catch (error: any) {
      set({
        showLoginErrorMsg: true,
        loginErrorText: error.response.data.message,
        logginIn: false
      })
    }
  },

  toggleLoginModal: () => {
    set((state) => ({
      displayLoginModal: !state.displayLoginModal
    }))
  },

  getFabrics: async () => {
    await client.fetchShopData()

    
    const data = client.shopData

    const { bicolorId, cuffsAndCollarId, innerContrastId, mainId, buttonId, collarTypeId } = get().preConfigured
    
    const defaultCollar = collarList.find(
      (entry: collarListProps) => entry.id === parseInt(collarTypeId)
    ) ?? collarList.sort((a: any, b: any) => a.label > b.label ? 1 : -1)[0]
    
    const defaultButton = data.buttons.sort((a: any, b: any) => a.title_en > b.title_en ? 1 : -1)[0]
    
    const defaultFabric = data.fabrics.sort((a: any, b: any) => a.name > b.name ? 1 : -1)[0] ?? {}
      
    const getConfigById = (type: any) => {
      switch (type) {
        case "bicolorTexture":
          return data.fabrics.find(
            (entry: FabricEntry) => entry.id === parseInt(bicolorId)
          ) ?? defaultFabric

        case "cuffsTexture":
          return data.fabrics.find(
            (entry: FabricEntry) => entry.id === parseInt(cuffsAndCollarId)
          ) ?? defaultFabric
        
        case "collarTexture":
          return data.fabrics.find(
            (entry: FabricEntry) => entry.id === parseInt(cuffsAndCollarId)
          ) ?? defaultFabric

        case "innerCollarTexture":
          return data.fabrics.find(
            (entry: FabricEntry) => entry.id === parseInt(innerContrastId)
          ) ?? defaultFabric

        case "innerContrastTexture":
          return data.fabrics.find(
            (entry: FabricEntry) => entry.id === parseInt(innerContrastId)
          ) ?? defaultFabric

        case "mainTexture":
          return data.fabrics.find(
            (entry: FabricEntry) => entry.id === parseInt(mainId)
          ) ?? defaultFabric

        case "buttonTexture":
          return data.buttons.find(
            (entry: FabricEntry) => entry.id === parseInt(buttonId)
          ) ?? defaultButton

        default:
          return
      }
    }

    const getFabricimage = (selectedFabric: any) =>
      selectedFabric.images.find(
        (image: FabricImage) => image.type === 'konfigurator_texture'
      ).image_url ?? selectedFabric.images.find(
        (image: FabricImage) => image.type === 'tile'
      ).image_url ?? {}

    set({
      buttonsList: data.buttons,
      fabricsList: data.fabrics,
      bicolorTexture: {
        ...getConfigById("bicolorTexture"),
        texture: await createTextureMap({
          url: getFabricimage(getConfigById("bicolorTexture"))
        }),
      },

      cuffsTexture: {
        ...getConfigById("cuffsTexture"),
        texture: await createTextureMap({
          url: getFabricimage(getConfigById("cuffsTexture")),
        }),
      },

      collarTexture: {
        ...getConfigById("collarTexture"),
        texture: await createTextureMap({
          url: getFabricimage(getConfigById("collarTexture")),
          repeat: defaultCollar.repeat,
        }),
      },

      innerCollarTexture: {
        ...getConfigById("innerCollarTexture"),
        texture: await createTextureMap({
          url: getFabricimage(getConfigById("innerCollarTexture")),
          repeat: defaultCollar.repeat,
        }),
      },

      mainTexture: {
        ...getConfigById("mainTexture"),
        texture: await createTextureMap({ url: getFabricimage(getConfigById("mainTexture")), rotation: Math.PI }),
      },

      innerContrastTexture: {
        ...getConfigById("innerContrastTexture"),
        texture: await createTextureMap({ url: getFabricimage(getConfigById("innerContrastTexture")), rotation: Math.PI }),
      },

      buttonTexture: {
        ...getConfigById("buttonTexture"),
        color: getConfigById("buttonTexture").color,
      },

      collarDesign: defaultCollar,
    })

    return data
  },

  /**
   * update configurator tab
   */
  setConfiguratorTab: (tab) => set({ configuratorTab: tab }),

  /**
   * update the texture from the currently open tab
   *
   * some tabs have multiple textures that we set
   */
  setTexture: async (newTexture) => {
    const state = get()

    switch (state.configuratorTab) {
      case 'bicolor': {
        const bicolorTexture = await createNewTexture(newTexture)
        return set({ bicolorTexture })
      }

      case 'cuffsAndCollar': {
        const [collarTexture, cuffsTexture] = await Promise.all([
          createNewTexture(newTexture, {
            repeat: state.collarDesign?.repeat
          }),
          createNewTexture(newTexture),
        ])

        return set({
          collarTexture,
          cuffsTexture,
        })
      }

      case 'innerContrast': {
        const [innerContrastTexture, innerCollarTexture] = await Promise.all([
          createNewTexture(newTexture, { rotation: Math.PI }),
          createNewTexture(newTexture, {
            repeat: state.collarDesign?.repeat
          }),
        ])
        return set({
          innerContrastTexture,
          innerCollarTexture,
        })
      }

      case 'main': {
        const mainTexture = await createNewTexture(
          newTexture,
          { rotation: Math.PI }
        )
        return set({
          mainTexture,
        })
      }

      // TODO: vielleicht nur 1 Textur für alle?
      case 'all': {
        const [
          bicolorTexture,
          cuffsTexture,
          collarTexture,
          innerCollarTexture,
          innerContrastTexture,
          mainTexture,
        ] = await Promise.all([
          createNewTexture(newTexture),
          createNewTexture(newTexture),
          createNewTexture(newTexture, {
            repeat: state.collarDesign?.repeat,
          }),
          createNewTexture(newTexture, {
            repeat: state.collarDesign?.repeat,
          }),
          createNewTexture(newTexture, { rotation: Math.PI }),
          createNewTexture(newTexture, { rotation: Math.PI }),
        ])

        return set({
          bicolorTexture,
          cuffsTexture,
          collarTexture,
          innerCollarTexture,
          innerContrastTexture,
          mainTexture,
        })
      }
    }
  },

  /**
   * update the button color
   */
  setButtonTexture: async (texture) => {
    set(({
      buttonTexture: {...texture, hex_code: texture.hex_code },
    }))
  },

  /**
   * update the collar design.
   * if the collar texture repeat is the same, the texture is not updated.
   */
  setCollarDesign: async (newCollarDesign) => {
    const { collarDesign, collarTexture, innerCollarTexture } = get()

    if (!isEqual(collarDesign?.repeat, newCollarDesign.repeat) && collarTexture?.url) {
      const [collar, innerCollar] = await Promise.all([
        createNewTexture(collarTexture, { repeat: collarDesign?.repeat }),
        createNewTexture(innerCollarTexture, { repeat: collarDesign?.repeat }),
      ])
      set({
        collarTexture: collar,
        innerCollarTexture: innerCollar,
        collarDesign: newCollarDesign,
      })
    } else {
      set({ collarDesign: newCollarDesign })
    }
  },
}))

export default useTextures
