import { useViewportSize } from '@mantine/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useTextures from '../../hooks/useTextures';
import useZoomedToCollar from '../../hooks/useZoomedToCollar';
import { collarListProps } from '../../lists/collarList';
import { styled } from '../../styles/stitches.config';

const Container = styled('button', {
  width: '250px',
  padding: '10px 0px',
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '12px',
  transition: 'border 250ms',

  border: '1px solid',
  borderColor: 'transparent',

  variants: {
    active: {
      true: {
        borderColor: '#0873E9',
      },
    },
  },
});

const Label = styled('span', {
  fontWeight: 500,
  paddingBottom: 10,
});

type ChangeCollarProps = collarListProps;

const ChangeCollar = ({ id, label, url, repeat }: ChangeCollarProps) => {
  const { activeCollarId, setCollarDesign, setConfiguratorTab, setShareParams } = useTextures((state) => ({
    activeCollarId: state.collarDesign?.id,
    setCollarDesign: state.setCollarDesign,
    setShareParams: state.setShareParams,
    setConfiguratorTab: state.setConfiguratorTab,
  }));

  const { t } = useTranslation();

  const setZoomedToCollar = useZoomedToCollar((state) => state.setZoomedToCollar);

  const isActive = activeCollarId === id;

  const { width } = useViewportSize();

  const handleClick = async () => {
    if (!isActive) {
      setZoomedToCollar(true);

      await setCollarDesign({ id, label, url, repeat });

      setShareParams();

      if (width <= 768) setConfiguratorTab('closed');
    }
  };

  return (
    <Container active={isActive} onClick={handleClick}>
      <img src={`./build/collar_icons_min/${url}`} alt={t(`collar_list.${label}`) as string} width={230} height={230} />

      <Label>{t(`collar_list.${label}`)}</Label>
    </Container>
  );
};

export default ChangeCollar;
