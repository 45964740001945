import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';
import { FabricPattern, FabricType } from './Fabric';
import { ThreadColor } from './ThreadColor';
import { ButtonColor } from './ButtonColor';
import { ShirtImage } from './ShirtImage';

/**
 * Abstract Instance class with hydrator methods
 * @package Befeni\FabricApi
 * @subpackage Instance
 * @author  Husain Fazel <me@husainfazel.com>
 */
export class ShirtGalleryItem extends InstanceAbstract implements InstanceInterface {

	/**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

	/** ====== Class Properties ====== */

	/**
	 * @type {number}
	 */ 
	private id: number = 0;

	/**
	 * @type {Date}
	 */ 
	private gallery_added_date: Date = new Date();

	/**
	 * @type {string}
	 */ 
	private gender: string = '';

	/**
	 * @type {string}
	 */ 
	private shirt_type: string = '';

	/**
	 * @type {number}
	 */ 
	private thread_color_id: number = 0;

	/**
	 * @type {number}
	 */ 
	private button_color_id: number = 0;

	/**
	 * @type {string}
	 */
	private fabric_name: string = '';

	/**
	 * @type {number}
	 */
	private fabric_id: number = 0;

	/**
	 * @type {string}
	 */
	private pattern: FabricPattern = 'solid';

	/**
	 * @type {string}
	 */
	private fabric_type: FabricType = 'Main';

	/**
	 * @type {number}
	 */ 
	private likes: number = 0;

	/**
	 * @type {number}
	 */ 
	private wishlists: number = 0;

	/**
	 * @type {boolean}
	 */
	private has_contrast: boolean = false;

	/**
	 * {
	 * 	   contrast_collar_fabric: "",
	 * 	   contrast_cuff_fabric: ""
	 * }
	 */ 
	private other_fabrics: object = {};

	/**
	 * @type {string}
	 */
	private shirt_title: string = '';

	/**
	 * @type {string}
	 */
	private fabric_composition_label: string = '';

	/**
	 * @type {object}
	 * 
	 * {
	 *     value: 25,
	 * 	   reason: "male"
	 * }
	 */ 
	private price: object = {};

	private is_sotw: boolean = false;

	private is_current_sotw: boolean = false;

	/**
	 * @type {object}
	 * 
	 * {
	 *     start: 25,
	 * 	   end: "male"
	 * }
	 */ 
	private sotw_dates: object = {};

	/** ====== Relationships ====== */

	/**
     * These are a list of relationships that will be searched
     * for in any raw data passed to the constructor of a 
     * ShirtGalleryItem instance. From there it will try to 
     * populate these relationships with instances of the 
     * relationship.
     *
     * See InstanceAbstract for an explanation of the format
     * below
     * @type {string[]}
     */
    public relationships: { [index: string]: string} = {
    	'threadColor': 'thread',
    	'buttonColor': 'button',
    	'shirtImage': 'image_variants'
    }

    /**
     * Each ShirtGalleryItem has an instance of ThreadColor,
     * this property stores a reference to that object.
     * @type {ThreadColor}
     */
    private threadColor: ThreadColor = {} as ThreadColor;

    /**
     * Each ShirtGalleryItem has an instance of ButtonColor,
     * this property stores a reference to that object.
     * @type {ButtonColor}
     */
    private buttonColor: ButtonColor = {} as ButtonColor;

    /**
     * Each ShirtGalleryItem has multiple images in differnt sizes,
     * this property is an array of ShirtImage instances belonging 
     * to this ShirtGalleryItem
     * @type {Array<ShirtImage>}
     */
    private shirtImages: Array<ShirtImage> = [] as Array<ShirtImage>;

    /**
     * @return {ThreadColor}
     */
    public getThread(): ThreadColor {
        return this.threadColor;
    }

    /**
     * @param  {ThreadColor} threadColor
     * @return {ShirtGalleryItem}
     */
    public setThread(threadColor: ThreadColor): ShirtGalleryItem {
        this.threadColor = threadColor;
        return this;
    }

    /**
     * @return {ThreadColor}
     */
    public getButton(): ButtonColor {
        return this.buttonColor;
    }

    /**
     * @param  {ButtonColor} buttonColor
     * @return {ShirtGalleryItem}
     */
    public setButton(buttonColor: ButtonColor): ShirtGalleryItem {
        this.buttonColor = buttonColor;
        return this;
    }

    /**
     * @return {Array<ShirtImage>}
     */
    public getImageVariants(): Array<ShirtImage> {
        return this.shirtImages;
    }

    /**
     * @param  {ShirtImage} image
     * @return {Fabric}           
     */
    public setImageVariants(image: ShirtImage): ShirtGalleryItem {
        this.shirtImages.push(image)
        return this;
    }

    /**
     * @param  {ShirtImage) => ShirtImage} callback
     * @return {Array<ShirtImage>}
     */
    public getImageVariant(callback: (item: ShirtImage) => ShirtImage): Array<ShirtImage> {
        return this.shirtImages.filter(callback);
    }

	
	/** ====== Accessors and Mutators ====== */

    /**
     * @return {number}
     */
	public getId(): number
	{
		return this.id;
	}

	/**
	 * @param {number}
	 * @return {ShirtGalleryItem}
	 */ 
	public setId(id: number): ShirtGalleryItem
	{
		this.id = id;
		return this;
	}

	/**
	 * @return {Date}
	 */ 
	public getGalleryAddedDate(): Date
	{
		return this.gallery_added_date;
	}

	/**
	 * @return {ShirtGalleryItem}
	 */ 
	public setGalleryAddedDate(gallery_added_date: string): ShirtGalleryItem
	{
		this.gallery_added_date = new Date(gallery_added_date);
		return this;
	}

	/**
	 * @return {string}
	 */ 
	public getGender(): string
	{
		return this.gender;
	}

	/**
	 * @param {string}
	 * @return {ShirtGalleryItem}
	 */ 
	public setGender(gender: string): ShirtGalleryItem
	{
		this.gender = gender;
		return this;
	}

	/**
	 * @return {string}
	 */ 
	public getShirtType(): string
	{
		return this.shirt_type;
	}

	/**
	 * @param {string}
	 * @return {ShirtGalleryItem}
	 */ 
	public setShirtType(shirt_type: string): ShirtGalleryItem
	{
		this.shirt_type = shirt_type;
		return this;
	}


	/**
	 * @param {number}
	 */ 
	public getThreadColorId(): number
	{
		return this.thread_color_id;
	}

	/**
	 * @param {number}
	 * @return {ShirtGalleryItem}
	 */ 
	public setThreadColorId(thread_color_id: number): ShirtGalleryItem
	{
		this.thread_color_id = thread_color_id;
		return this;
	}


	/**
	 * @return {number}
	 */ 
	public getButtonColorId(): number
	{
		return this.button_color_id;
	}

	/**
	 * @param {number}
	 * @return {ShirtGalleryItem}
	 */ 
	public setButtonColorId(button_color_id: number): ShirtGalleryItem
	{
		this.button_color_id = button_color_id;
		return this;
	}

	/**
	 * @return {number}
	 */ 
	public getFabricId(): number
	{
		return this.fabric_id;
	}

	/**
	 * @param {number}
	 * @return {ShirtGalleryItem}
	 */ 
	public setFabricId(fabric_id: number): ShirtGalleryItem
	{
		this.fabric_id = fabric_id;
		return this;
	}

	/**
	 * @return {string}
	 */ 
	public getFabricName(): string
	{
		return this.fabric_name;
	}

	/**
	 * @param {string}
	 * @return {ShirtGalleryItem}
	 */ 
	public setFabricName(fabric_name: string): ShirtGalleryItem
	{
		this.fabric_name = fabric_name;
		return this;
	}


	/**
	 * @return {FabricPattern}
	 */ 
	public getPattern(): FabricPattern
	{
		return this.pattern;
	}

	/**
	 * @param {FabricPattern}
	 * @return {ShirtGalleryItem}
	 */ 
	public setPattern(pattern: FabricPattern): ShirtGalleryItem
	{
		this.pattern = pattern;
		return this;
	}

	/**
	 * @return {FabricType}
	 */ 
	public getFabricType(): FabricType
	{
		return this.fabric_type;
	}

	/**
	 * @param {FabricType}
	 * @return {ShirtGalleryItem}
	 */ 
	public setFabricType(fabric_type: FabricType): ShirtGalleryItem
	{
		this.fabric_type = fabric_type;
		return this;
	}

	/**
	 * @return {number}
	 */ 
	public getLikes(): number
	{
		return this.likes;
	}

	/**
	 * @param {number}
	 * @return {ShirtGalleryItem}
	 */ 
	public setLikes(likes: number): ShirtGalleryItem
	{
		this.likes = likes;
		return this;
	}

	/**
	 * @return {number}
	 */ 
	public getWishlists(): number
	{
		return this.wishlists;
	}

	/**
	 * @param {number}
	 * @return {ShirtGalleryItem}
	 */ 
	public setWishlists(wishlists: number): ShirtGalleryItem
	{
		this.wishlists = wishlists;
		return this;
	}

	/**
	 * @return {boolean}
	 */ 
	public getHasContrast(): boolean
	{
		return this.has_contrast;
	}

	/**
	 * @param {boolean}
	 * @return {ShirtGalleryItem}
	 */ 
	public setHasContrast(has_contrast: boolean): ShirtGalleryItem
	{
		this.has_contrast = has_contrast;
		return this;
	}

	/**
	 * @return {object}
	 */ 
	public getOtherFabrics(): object
	{
		return this.other_fabrics;
	}

	/**
	 * @param {object}
	 * @return {ShirtGalleryItem}
	 */ 
	public setOtherFabrics(other_fabrics: object): ShirtGalleryItem
	{
		this.other_fabrics = other_fabrics;
		return this;
	}

	/**
	 * @return {string}
	 */ 
	public getShirtTitle(): string
	{
		return this.shirt_title;
	}

	/**
	 * @param {string}
	 * @return {ShirtGalleryItem}
	 */ 
	public setShirtTitle(shirt_title: string) : ShirtGalleryItem
	{
		this.shirt_title = shirt_title;
		return this;
	}

	/**
	 * @return {string}
	 */ 
	public getFabricCompositionLabel(): string
	{
		return this.fabric_composition_label;
	}

	/**
	 * @param {string}
	 * @return {ShirtGalleryItem}
	 */ 
	public setFabricCompositionLabel(fabric_composition_label: string): ShirtGalleryItem
	{
		this.fabric_composition_label = fabric_composition_label;
		return this;
	}

	/**
	 * @return {object}
	 */ 
	public getPrice(): object {
		return this.price;
	}

	/**
	 * @param {object}
	 * @return {ShirtGalleryItem}
	 */ 
	public setPrice(price: object): ShirtGalleryItem {
		this.price = price;
		return this;
	}

	/**
	 * @return {boolean}
	 */ 
	public getIsSotw(): boolean {
		return this.is_sotw;
	}

	/**
	 * @param {boolean}
	 * @return {ShirtGalleryItem}
	 */ 
	public setIsSotw(is_sotw: boolean): ShirtGalleryItem {
		this.is_sotw = is_sotw;
		return this;
	}

	/**
	 * @return {boolean}
	 */
	public getIsCurrentSotw(): boolean {
		return this.is_current_sotw;
	}

	/**
	 * @param {boolean}
	 * @return {ShirtGalleryItem}
	 */ 
	public setIsCurrentSotw(is_current_sotw: boolean): ShirtGalleryItem {
		this.is_current_sotw = is_current_sotw;
		return this;
	}

	/**
	 * @return {object}
	 */ 
	public getSotwDates(): object {
		return this.sotw_dates;
	}

	/**
	 * @param {object}
	 * @return {ShirtGalleryItem}
	 */ 
	public setSotwDates(sotw_dates: object): ShirtGalleryItem {
		this.sotw_dates = sotw_dates;
		return this;
	}

}