import Base from '../base'
import Dictionary, { PaginationDetails } from '../collection';
import { GenericResult, CollectionResultType, ResultType } from '../utils';
import { ShirtGalleryBuilder } from '../builder';
import { ShirtGalleryItem } from '../instance';
import { FabricPattern, FabricType, FabricWeave } from '../instance/Fabric';


export type ShirtGalleryFilters = {
	fabricIds: number[],
	familyIds: number[],
	shirtId: number,
	contrastFamilyIds: number[],
	patterns: FabricPattern[],
	types: FabricType[],
	weaves: FabricWeave[],
	compositionIds: number[],
	designs: string[],
	collarStyles: number[],
	cuffStyles: number[],
	myShirtOnly: boolean,
	sotwOnly: boolean
};

export class ShirtGalleryTrait extends Base {

	/**
	 * Get the list of shirt
	 * 
	 * @param {ShirtGalleryFilters} filters
	 * @param {number} consultantId
	 * @param {number} clientId
	 * @param {string} orderField
	 * @param {string} orderDirection
	 * @param {number} page
	 * @param {number} perPage
	 * 
	 * @return Promise<Dictionary<ShirtGalleryItem>>
	 */ 
	async getShirtGalleryShirts(filters: ShirtGalleryFilters, 
		orderField: string, orderDirection:  'asc' | 'desc', page: number, perPage: number): Promise<Dictionary<ShirtGalleryItem>>  {


		const collection = new Dictionary<ShirtGalleryItem>();


		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');

		/**
		 * Set filters and pagination
		 */ 
		builder.includeFabrics(filters.fabricIds)
			.includeFamilies(filters.familyIds)
			.includeShirtId(filters.shirtId)
			.includeContrastFamilies(filters.contrastFamilyIds)
			.includeFabricPatterns(filters.patterns)
			.includeFabricTypes(filters.types)
			.includeFabricWeaves(filters.weaves)
			.includeFabricCompositions(filters.compositionIds)
			.includeDesigns(filters.designs)
			.includeCollarStyles(filters.collarStyles)
			.includeCuffStyles(filters.cuffStyles)
			.page(page).resultsPerPage(perPage)
			.sortBy(orderField, orderDirection);

		const results = await builder.execute("get", 'shirt_orders/gallery', {
            my_shirts      : filters.myShirtOnly,
            sotw_only      : filters.sotwOnly
		});

        if(!results) {
            return collection;
        } 

        const resArr = <CollectionResultType>results.getResults();
        var count = 1;
        resArr.data.collection.map(result => {
            collection.addItem(count.toString(), <ShirtGalleryItem>ShirtGalleryItem.createFromRaw(result, 'ShirtGalleryItem'));
            count++;
        })
		collection.setPagination(<PaginationDetails>resArr.data.pagination);
        return collection;

	}

	/**
	 * Get the data required to display the advanced filters on the shirt gallery page
	 * 
	 * @param {number} consultantId
	 * @param {number} clientId
	 * 
	 * @return {Promise<ResultType>}
	 */ 
	async getShirtGalleryBootstrapData(): Promise<ResultType> {
		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');
		const results = await builder.execute("get", 'shirt_orders/gallery/bootstrap', {});

		if(!results) {
			throw new Error('Error while fetching shirt gallery bootstrap data');
		}
		return <ResultType>results.getResults();
	}

	/**
	 * Get the list of likes and wishlist 
	 */ 
	async getUserInteractions(): Promise<ResultType> {
		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');
		const results = await builder.execute("get", 'shirt_orders/gallery/interactions', {});

		if(!results) {
			throw new Error('Error while fetching shirt gallery bootstrap data');
		}
		return <ResultType>results.getResults();
	}

	/**
	 * Add like to a shirt
	 * 
	 * @param {number} shirtOrderId
	 * @param {number} consultantId
	 * @param {number} clientId
	 * 
	 * @return {Promise<ResultType>}
	 */ 
	async addLike(shirtOrderId: number): Promise<ResultType> {
		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');
		const results = await builder.execute("post", 'shirt_orders/gallery/' + shirtOrderId + '/like', {});

		if(!results) {
			throw new Error('Cannot add like to shirt [' + shirtOrderId + ']');
		}

		return <ResultType>results.getResults();
	}

	/**
	 * Remove like to a shirt
	 * 
	 * @param {number} shirtOrderId
	 * @param {number} consultantId
	 * @param {number} clientId
	 * 
	 * @return {Promise<ResultType>}
	 */ 
	async removeLike(shirtOrderId: number): Promise<ResultType> {
		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');
		const results = await builder.execute("delete", 'shirt_orders/gallery/' + shirtOrderId + '/like', {});

		if(!results) {
			throw new Error('Cannot remove like from shirt [' + shirtOrderId + ']');
		}

		return <ResultType>results.getResults();
	}

	/**
	 * Add shirt to user wishlist
	 * 
	 * @param {number} shirtOrderId
	 * @param {number} consultantId
	 * @param {number} clientId
	 * 
	 * @return {Promise<ResultType>}
	 */ 
	async addToWishList(shirtOrderId: number): Promise<ResultType> {
		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');
		const results = await builder.execute("post", 'shirt_orders/gallery/' + shirtOrderId + '/wishlist', {});

		if(!results) {
			throw new Error('Cannot add shirt [' + shirtOrderId + '] to whislist');
		}

		return <ResultType>results.getResults();
	}

	/**
	 * Remove shirt from user wishlist
	 * 
	 * @param {number} shirtOrderId
	 * @param {number} consultantId
	 * @param {number} clientId
	 * 
	 * @return {Promise<ResultType>}
	 */ 
	async removeFromWishList(shirtOrderId: number, consultantId: number, clientId: number): Promise<ResultType> {
		let builder = <ShirtGalleryBuilder>this.getBuilder('ShirtGalleryBuilder');
		const results = await builder.execute("delete", 'shirt_orders/gallery/' + shirtOrderId + '/wishlist', {});

		if(!results) {
			throw new Error('Cannot remove shirt [' + shirtOrderId + '] from whislist');
		}

		return <ResultType>results.getResults();
	}
}