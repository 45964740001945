import Base from '../base';
import { Builder } from '../builder';
import { ResultType } from '../utils';


export class AccountTrait extends Base {

	/**
	 * Update the consultant data in the Production System database
	 * @param {number} consultantId
	 * @param {[index: string]: any} consultantData - An associative array that accept the following keys:
     *          'first_name', 'last_name', 'email',
     *           'gender', 'street', 'zip', 'city', 'country',
     *           'birthday', 'phone', 'mobile', 'street_number'
     * 
     * All other key will be ignored by the Production System
	 */ 
	async updateConsultant(consultantId: number, consultantData: { [index: string]: any }): Promise<ResultType> {

		let builder = <Builder>this.getBuilder();

		const results = await builder.execute("put", 'account/consultant/' + consultantId, consultantData);

		if(!results) {
			throw new Error('Error while trying to login to the Consultant System');
		}

		return <ResultType>results.getResults();
	}
}