import { ThreadColor, ButtonColor, Fabric, FabricBook, FabricBookCategory, ShirtGalleryItem, Consultant } from '../instance';
import * as instances from "../instance";

export type ModelNames = 'ThreadColor'|'ButtonColor'|'Fabric'|'FabricBook'|'FabricBookCategory'|'ShirtGalleryItem'|'Consultant';
export interface InstanceInterface {};

export abstract class InstanceAbstract {

    /**
     * This a list of relationships which is overwritten 
     * in the actual instance itself.
     *
     * In order for the automatic hydration of the models to
     * be successful, the structure of this object should be:
     * {
     *  <instance name>: <field expected in raw data>
     * }
     * @type { [string]: string}
     */
    public relationships: { [index: string]: string} = {}

    /**
     * Create an instance of a class
     * @param  {string} className
     * @param  {any[]}  ...args
     * @return {any}
     */
    public getInstance(className: string, ...args: any[]): InstanceInterface {
        const instance: any = instances;
        if (typeof instance[className] !== undefined) {
            return new instance[className]((args.length > 0) ? args[0] : null);
        } else {
            throw new Error("Class not found: " + className);
        }
    }

    /**
     * Static factory method to create new instances
     * @param  {object} rawData 
     * @param  {ModelNames} type    
     * @return {InstanceInterface}         
     */
    public static createFromRaw(rawData: object, type: ModelNames): InstanceInterface {
        switch(type) {
            case 'ThreadColor':
                return new ThreadColor(rawData);
                break;
            case 'ButtonColor':
                return new ButtonColor(rawData);
                break;
            case 'Fabric':
                return new Fabric(rawData);
                break;
            case 'FabricBook':
                return new FabricBook(rawData);
                break;
            case 'FabricBookCategory':
                return new FabricBookCategory(rawData);
                break;
            case 'ShirtGalleryItem':
                return new ShirtGalleryItem(rawData);
                break;
            case 'Consultant':
                return new Consultant(rawData);
                break;
        }
    }

    /**
     * Populates the instance with raw data
     * @param object rawData key/value pair of data defining the instance
     * @param {InstanceAbstract} obj
     */
    public importFromObj(rawData: { [index: string]: any }, obj: InstanceAbstract): void {
        const prototype = Object.getPrototypeOf(obj);

        // add base properties
        Object.getOwnPropertyNames(prototype).forEach((name: string): void => {
            if(name.slice(0,3) == 'set') {    
                // check that it is not a relationship method
                if(!this.isRelationshipMethod(name)) {
                    let prop = this.toSnakeCase(name.slice(3));
                    if(rawData.hasOwnProperty(prop)) {
                        prototype[name].call(obj, rawData[prop]);
                    } 
                }
            }
        });

        // add relationships
        if(Object.keys(this.relationships).length > 0) {
            Object.keys(this.relationships).map((key: string) => {
                let formattedRelationship = this.toSnakeCase(this.relationships[key]);
                let relationshipSetter = this.toCamelCase('set_' + this.toSnakeCase(this.relationships[key]));
                let instanceName = this.toClassName(this.toSnakeCase(key));

                if(rawData.hasOwnProperty(formattedRelationship)) {
                    if(rawData[formattedRelationship] !== null) {
                        if(Array.isArray(rawData[formattedRelationship])) {

                            /** Relationship is an array */
                            for(var objData of rawData[formattedRelationship]) {
                                let newObj = this.getInstance(instanceName, objData);
                                prototype[relationshipSetter].call(obj, newObj);
                            }
                        } else {
                            let newObj = this.getInstance(instanceName, rawData[formattedRelationship]);
                            prototype[relationshipSetter].call(obj, newObj);
                        }
                    }
                }
            });
        }

    }

    public isRelationshipMethod(method: string): boolean {
        const relationshipMethods = Object.values(this.relationships).map(field => {
            return 'set' + this.toClassName(field);
        })
        return (relationshipMethods.indexOf(method) !== -1);
    } 

    /**
     * Converts any snake_case string to ccamelCase
     * @param  {string} input
     * @return {string}      
     */
    public toCamelCase(input: string): string {
        return input.toLowerCase()
            // Replaces any - or _ characters with a space 
            .replace(/[-_]+/g, ' ')
            // Removes any non alphanumeric characters 
            .replace(/[^\w\s]/g, '')
            // Uppercases the first character in each group immediately following a space 
            // (delimited by spaces) 
            .replace(/ (.)/g, function($1) { return $1.toUpperCase(); })
            // Removes spaces 
            .replace(/ /g, '' );
    } 

    /**
     * Converts any camelCase string to snake_case
     * @param  {string} input
     * @return {string}      
     */
    public toSnakeCase(input: string): string {
        return input
            .split('')
            // iterates over each character and adds a space before a capital letter 
            // whilst also transforming to lower case. Space is not added before
            // first instance of a capital lettr
            .map((letter: string, index: number): string => {
              if (/[A-Z]/.test(letter)) {
                  return (index) ? ` ${letter.toLowerCase()}` : letter.toLowerCase();
              }
              if (/[1-9]/.test(letter)) {
                  return (index) ? ` ${letter}` : letter;
              }
              return letter;
            })
            .join('')
            // replace dashes with underscores
            .replace(/-/g, '_')
            // replace spaces with underscores
            .replace(/\s+/g, '_')
    }

    /**
     * Takes a camel case string (like fabric_composition_label)
     * and converts to class name like FabricCompositionLabel 
     * @param  {string} input
     * @return {string}
     */
    public toClassName(input: string): string {
        return input.split('_')
            .map(str => str.charAt(0).toUpperCase() + str.slice(1))
            .join('');
    }

}