import { InstanceAbstract } from '../instance';

export type PaginationDetails = {
  current_page: number;
  last_page: number;
  per_page: number;
  total_records: number;
}

interface DictionaryInterface<T> {
  /**
   * Add a key=>value part to the dictionary
   * @param {string} key
   * @param {T}      value
   */
  addItem(key: string, value: T): boolean;

  /**
   * Removes an item with a given key from 
   * the dictionary
   * 
   * @param  {string} key
   * @return {T}         
   */
  deleteItem(key: string): boolean;

  /**
   * Get an item form the dictionary by key
   * @param  {string} key
   * @return {T}         
   */
  getItem(key: string): T;

  /**
   * Check if the dictionary contains a certain
   * key
   * @param  {string}  key [description]
   * @return {boolean}     [description]
   */
  checkKeyExists(key: string): boolean;

  /**
   * Returns the size of the current dictionary
   * @return {number}
   */
  size(): number;

  /**
   * Get all the keys in the dictionary
   * @return {string[]}
   */
  getKeys(): string[];

  /**
   * Get all the values in the dictionary
   * @return {T[]}
   */
  getValues(): T[];

  /**
   * Applies a callback function to the items in
   * the dictionary to return a filtered list
   * of items
   * @param  {T) => T} callback
   * @return {T[]}
   */
  filter(callback: (item: T) => boolean): Dictionary<T>;

  setPagination(pagination: PaginationDetails): DictionaryInterface<T>;
}

export default class Dictionary<T> implements DictionaryInterface<T> {

  private items: { [index: string]: T } = {};

  private count: number = 0;

  private current_page: number = 0;

  private last_page: number = 0;

  private per_page: number = 0;

  private total_records: number = 0;

  /**
   * Add a key=>value part to the dictionary
   * @param {string} key
   * @param {T}      value
   */
  addItem(key: string, value: T) {
    if(this.items.hasOwnProperty(key)) {
      return false;
    }

    this.items[key] = value;
    this.count++;
    return true;
  }

  /**
   * Removes an item with a given key from 
   * the dictionary
   * 
   * @param  {string} key
   * @return {T}         
   */
  deleteItem(key: string): boolean {
    if(!this.checkKeyExists(key)) {
      return false;
    }

    delete this.items[key];
    this.count--;
    return true;
  }

  /**
   * Get an item form the dictionary by key
   * @param  {string} key
   * @return {T}         
   */
  getItem(key: string): T {
    return this.items[key];
  }

  /**
   * Check if the dictionary contains a certain
   * key
   * @param  {string}  key [description]
   * @return {boolean}     [description]
   */
  checkKeyExists(key: string): boolean {
    return this.items.hasOwnProperty(key);
  }

  /**
   * Returns the size of the current dictionary
   * @return {number}
   */
  size(): number {
    return this.count;
  }

  /**
   * Get all the keys in the dictionary
   * @return {string[]}
   */
  getKeys(): string[] {
    let keys: string[] = [];

    for(let property in this.items) {
      if(this.items.hasOwnProperty(property)) {
        keys.push(property);
      }
    }

    return keys;
  }

  /**
   * Get all the values in the dictionary
   * @return {T[]}
   */
  getValues(): T[] {
    let values: T[] = [];

    for(let property in this.items) {
      if(this.items.hasOwnProperty(property)) {
        values.push(this.items[property]);
      }
    }

    return values;
  }

  /**
   * Clears a collection
   * @return void
   */
  clear() {
     this.count = 0;
     this.items = {};
  }

  /**
   * Applies a callback function to the items in
   * the dictionary to return a filtered list
   * of items
   * @param  {T) => T} callback
   * @return {T[]}
   */
  filter(callback: (item: T) => boolean): Dictionary<T> {
    const dictItems = Object.values(this.items);
    const newItems = dictItems.filter(callback);
    this.clear();

    var count = 1;
    newItems.map(result => {
        this.addItem(count.toString(), result);
        count++;
    })

    return this;
  }
  
  public setPagination(pagination: PaginationDetails): DictionaryInterface<T> {
    this.current_page = pagination.current_page;
    this.last_page = pagination.last_page;
    this.per_page = pagination.per_page;
    this.total_records = pagination.total_records;
    return this;
  }
}
