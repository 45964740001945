import Base from '../base'
import Dictionary from '../collection';
import { GenericResult, ResultType } from '../utils';
import { ButtonColor } from '../instance';
import { ButtonBuilder } from '../builder';

export class ButtonTrait extends Base {

    /**
     * Get a list of all buttons
     * @param {string} orderField
     * @param {string} orderDirection
     * @return {Promise<Dictionary<ButtonColor>>} 
     */
    async getAllButtons(orderField: string = 'order_color', orderDirection: 'asc' | 'desc' = 'asc'): Promise<Dictionary<ButtonColor>> {
        const collection = new Dictionary<ButtonColor>();

        const builder = <ButtonBuilder>this.getBuilder('ButtonBuilder')
            .select(['id', 'title_en', 'title_th',
                'title_de', 'title_translatable', 'internal_number', 'order_color'])
            .sortBy(orderField, orderDirection);

        const results = await builder.execute("get", 'fabrics/button_colors', {});

        if(!results) {
            return collection;
        } 

        const resArr = <ResultType>results.getResults();
        var count = 1;
        (resArr.data as Object[]).map(result => {
            collection.addItem(count.toString(), <ButtonColor>ButtonColor.createFromRaw(result, 'ButtonColor'));
            count++;
        });

        return collection;
    }

    /**
     * Get a single button color
     * @param {number} id
     * @return {Promise<ButtonColor>}
     */ 
    async getButton(id:number): Promise<ButtonColor> {
        const builder = <ButtonBuilder>this.getBuilder('ButtonBuilder')
            .select(['id', 'title_en', 'title_th',
                'title_de', 'title_translatable', 'internal_number', 'order_color']);

        const results = await builder.execute("get", 'fabrics/button_colors/' + id, {});

        if(!results) {
            throw new Error('Unable to find button');
        }
        const result = <ResultType>results.getResults();
        return <ButtonColor>ButtonColor.createFromRaw(result.data, 'ButtonColor');
    }
}


