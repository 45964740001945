import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import React from 'react';

import { styled } from '../styles/stitches.config';

const SCROLLBAR_SIZE = 6;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  borderRadius: 4,
  overflow: 'hidden',
});

const Viewport = styled(ScrollAreaPrimitive.Viewport, {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

const ScrollbarContainer = styled(ScrollAreaPrimitive.Scrollbar, {
  display: 'flex',
  // ensures no selection
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',
  padding: 2,
  transition: 'background 160ms ease-out',

  '&[data-orientation="vertical"]': {
    width: SCROLLBAR_SIZE,
  },

  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
});

const Scrollbar = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
});

interface ScrollAreaProps {
  children: React.ReactNode;
  height?: React.CSSProperties['height'];
}

const ScrollArea = ({ children, height = 'auto' }: ScrollAreaProps) => (
  <StyledScrollArea type='always' css={{ height }}>
    <Viewport>{children}</Viewport>

    <ScrollbarContainer orientation='vertical'>
      <Scrollbar />
    </ScrollbarContainer>

    <ScrollbarContainer orientation='horizontal'>
      <Scrollbar />
    </ScrollbarContainer>
  </StyledScrollArea>
);

export default ScrollArea;
