import BuilderInterface from './BuilderInterface';
import { Builder } from './Builder';
import Base from '../base';
import { GenericResult } from '../utils';
import { HttpMethod } from '../types';
import { FabricPattern, FabricType, FabricWeave } from '../instance/Fabric';

export class ShirtGalleryBuilder extends Builder implements BuilderInterface {

	/**
	 * Set fabric filters
	 * 
	 * @param {numbers[]} fabricIds
	 */ 
	public includeFabrics(fabricIds: number[]): ShirtGalleryBuilder {
		if(fabricIds.length > 0) {
			this.whereEquals('fabric', fabricIds);
		}
		return this;
	}

	/**
	 * Set shirt filters
	 * 
	 * @param {numbers} shirtId
	 */ 
	 public includeShirtId(shirtId: number): ShirtGalleryBuilder {
		if(shirtId) {
			this.whereEquals('id', shirtId);
		}
		return this;
	}

	/**
	 * Set the fabric family filters
	 * 
	 * @param {number[]} familyIds
	 */ 
	public includeFamilies(familyIds: number[]): ShirtGalleryBuilder {
		if(familyIds.length > 0) {
			this.whereEquals('family', familyIds);
		}		
		return this;
	}

	/**
	 * Set the contrast fabric family filters
	 * 
	 * @param {number[]} contrastFamilyIds
	 */ 
	public includeContrastFamilies(contrastFamilyIds: number[]): ShirtGalleryBuilder {
		if(contrastFamilyIds.length > 0) {
			this.whereEquals('contrast_family', contrastFamilyIds);
		}		
		return this;
	}

	/**
	 * Set the fabric pattern filters
	 * 
	 * @param {FabricPattern[]} patterns
	 */
	public includeFabricPatterns(patterns: FabricPattern[]): ShirtGalleryBuilder {
		if(patterns.length > 0) {
			this.whereEquals('pattern', patterns);
		}		
		return this;
	}

	/**
	 * Set the fabric type filters
	 * 
	 * @param {FabricType[]} types
	 */
	public includeFabricTypes(types: FabricType[]): ShirtGalleryBuilder {
		if(types.length > 0) {
			this.whereEquals('fabrics.type', types);
		}		
		return this;
	}

	/**
	 * Set the fabric weave filters
	 * 
	 * @param {FabricWeave[]} weaves
	 */
	public includeFabricWeaves(weaves: FabricWeave[]): ShirtGalleryBuilder {
		if(weaves.length > 0) {
			this.whereEquals('weave', weaves);
		}		
		return this;
	}

	/**
	 * Set the fabric composition filters
	 * 
	 * @param {number[]} compositionIds
	 */
	public includeFabricCompositions(compositionIds: number[]): ShirtGalleryBuilder {
		if(compositionIds.length > 0) {
			this.whereEquals('fabric_compositions', compositionIds);
		}		
		return this;
	}

	/**
	 * Set the shirt design filters
	 * 
	 * @param {string[]} designs
	 */
	public includeDesigns(designs: string[]): ShirtGalleryBuilder {
		if(designs.length > 0) {
			this.whereEquals('design', designs);
		} 		
		return this;
	}

	/**
	 * Set the shirt collar style filters
	 * 
	 * @param {number[]} collarStyleIds
	 */
	public includeCollarStyles(collarStyleIds: number[]): ShirtGalleryBuilder {
		if(collarStyleIds.length > 0) {
			this.whereEquals('collar', collarStyleIds);
		}		
		return this;
	}

	/**
	 * Set the shirt collar style filters
	 * 
	 * @param {number[]} cuffStyleIds
	 */
	public includeCuffStyles(cuffStyleIds: number[]): ShirtGalleryBuilder {
		if(cuffStyleIds.length > 0) {
			this.whereEquals('cuff', cuffStyleIds);
		}		
		return this;
	}

}