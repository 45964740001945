import React from 'react';

import { styled } from '../../../styles/stitches.config';

const Container = styled('button', {
  position: 'relative',
  width: 120,
  height: 120,
  cursor: 'pointer',

  '@mobile': {
    width: 110,
    height: 110,
  },

  '&:not(:last-of-type)': {
    marginRight: 12,

    '@tablet': {
      marginRight: 4,
    },

    '@mobile': {
      marginRight: 2,
    },
  },

  '> svg': {
    width: '100%',
    height: '100%',
  },
});

interface ConfiguratorOpenButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

const ConfiguratorOpenButton = ({ children, ...rest }: ConfiguratorOpenButtonProps) => {
  return <Container {...rest}>{children}</Container>;
};

export default ConfiguratorOpenButton;
