import React from 'react';
import ZoomIn from '../../images/zoomIn.svg';

const ZoomInIcon = () => {
  return (
    <img src={ZoomIn} alt="" />
  );
};

export default ZoomInIcon;
