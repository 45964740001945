import React from 'react';

type CollarIconProps = React.SVGProps<SVGSVGElement>;

const CollarIcon = (props: CollarIconProps) => {
  return (
    <svg width='349' height='377' viewBox='0 0 349 377' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#fff' stroke='#000' d='M.5 77.5h299v299H.5z' />
      <path
        d='M73 148.158c-2.2 1.1-5.3 6.4-15.9 27.6l-13.1 26.2 21.5 49c18.8 42.6 21.8 48.9 23.7 48.9 1.3 0 13.8-9.2 31.1-22.8l29-22.8 27.7 21.7c18.8 14.7 28.6 21.8 30.3 21.9 2.3 0 4-3.5 25.3-50 13.1-28.6 22.7-50.9 22.4-52-.3-1.8-27.5-44.4-30.1-47.2-1.7-1.9-148.1-2.3-151.9-.5zm140.1 5.7c.2.2-7.5 11.6-17 25.4l-17.3 25h-30l-30 .1-17.6-25.3-17.7-25.2 14.2-.5c13.6-.5 114.8 0 115.4.5zm-104.8 44.5c17.2 24.5 32.5 46.3 34 48.5l2.8 4.1-26.9 21.2c-14.9 11.7-27.3 21-27.8 20.8-.4-.3-9.7-20.8-20.6-45.7l-19.9-45.1 12.2-24.5c8.2-16.4 12.7-24.3 13.7-24.1.7.2 15.4 20.3 32.5 44.8zm127.6-23.5c7.6 11.5 13.7 21.4 13.7 22 0 .8-41.6 92.5-42.9 94.7-.6 1-52.9-40.2-52.8-41.5.1-.7 12.6-19.2 27.9-41.2 38.9-56.1 38.6-55.6 39.6-55.3.4.2 7 9.7 14.5 21.3zm-61.8 35.4c-.1.5-5.9 9-12.7 18.9l-12.5 17.9-12.8-18.1c-7-9.9-12.7-18.4-12.8-18.9 0-.4 11.5-.7 25.6-.7 14.9 0 25.4.4 25.2.9z'
        fill='#000'
      />
    </svg>
  );
};

export default CollarIcon;
