import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

import { FabricBook } from './FabricBook';

export class FabricBookCategory extends InstanceAbstract implements InstanceInterface {

	/**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /**
     * @var number
     */
    private id: number = 0;

    /**
     * @var string
     */
    private title: string = '';

    /**
     * @var string
     */
    private slug: string = '';

    /**
     * @var number
     */
    private order: number = 0;


    /**
     * @var number
     */
    private parent_fabric_book_category_id: number = 0;

    /**
     * @var boolean
     */
    private can_have_child: boolean = false;

    /**
     * @var boolean
     */
    private can_have_user_books: boolean = false;


    /** ====== Relationships ====== */
    /**
     * These are a list of relationships that will be searched
     * for in any raw data passed to the constructor of a Fabric
     * instance. From there it will try to populate these 
     * relationships with instances of the relationship.
     *
     * See InstanceAbstract for an explanation of the format
     * below
     * @type {string[]}
     */

    public relationships: { [index: string]: string} = {
        'fabricBook': 'books'
    }


    /**
     * Each category can has multiple books, this property is an array
     * of FabricBook instances in this FabricBookCategory
     * @type {Array<FabricBook>}
     */
    private categoryBooks: Array<FabricBook> = [] as Array<FabricBook>;

    /**
     * @return number
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param {number} id
     * @return FabricBookCategory
     */
    public setId(id: number): FabricBookCategory {
        this.id = id;
        return this;
    }

    /**
     * @return string
     */
    public getTitle(): string {
        return this.title;
    }

    /**
     * @param {string} title
     * @return FabricBookCategory
     */
    public setTitle(title: string): FabricBookCategory {
        this.title = title;
        return this;
    }

    /**
     * @return string
     */
    public getSlug(): string {
        return this.slug;
    }

    /**
     * @param {string} slug
     * @return FabricBookCategory
     */
    public setSlug(slug: string): FabricBookCategory {
        this.slug = slug;
        return this;
    }

    /**
     * @return number
     */
    public getOrder(): number {
        return this.order;
    }

    /**
     * @param {number} order
     * @return FabricBookCategory
     */
    public setOrder(order: number): FabricBookCategory {
        this.order = order;
        return this;
    }

    /**
     * @return number
     */
    public getParentFabricBookCategoryId(): number {
        return this.parent_fabric_book_category_id;
    }

    /**
     * @param {number} parent_fabric_book_category_id
     * @return FabricBookCategory
     */
    public setParentFabricBookCategoryId(parent_fabric_book_category_id: number): FabricBookCategory {
        this.parent_fabric_book_category_id = parent_fabric_book_category_id;
        return this;
    }

    /**
     * @return boolean
     */
    public getCanHaveChild(): boolean {
        // Because MySQL store boolean as 0,1, but we need real boolean
        return !!(this.can_have_child);
    }

    /**
     * @param {boolean} can_have_child
     * @return FabricBookCategory
     */
    public setCanHaveChild(can_have_child: boolean): FabricBookCategory {
        this.can_have_child = can_have_child;
        return this;
    }

    /**
     * @return boolean
     */
    public getCanHaveUserBooks(): boolean {
        // Because MySQL store boolean as 0,1, but we need real boolean
        return !!(this.can_have_user_books);
    }

    /**
     * @param {boolean} can_have_user_books
     * @return FabricBookCategory
     */
    public setCanHaveUserBooks(can_have_user_books: boolean): FabricBookCategory {
        this.can_have_user_books = can_have_user_books;
        return this;
    }

    /**
     * @return Array<FabricBook>
     */
    public getBooks(): Array<FabricBook> {
        return this.categoryBooks;
    }

    /**
     * @param {FabricBook} book
     * @return FabricBookCategory
     */
    public setBooks(book: FabricBook): FabricBookCategory {
        this.categoryBooks.push(book);
        return this;
    }

}