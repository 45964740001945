import { useGLTF } from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import useTextures from '../../hooks/useTextures';

type GLTFResult = GLTF & {
  nodes: {
    bicolor: THREE.Mesh;
    haubtstoff: THREE.Mesh;
    ['innen-kontrast']: THREE.Mesh;
    ['innere-manschetten']: THREE.Mesh;
    knöpfe: THREE.Mesh;
    manschetten: THREE.Mesh;
    ärmel: THREE.Mesh;
  };
};

const ShirtModel = () => {
  const { nodes } = useGLTF('./build/models/shirt_only.glb') as unknown as GLTFResult;

  const { bicolorTexture, buttonColor, cuffsTexture, mainTexture, innerContrastTexture } = useTextures((store) => ({
    bicolorTexture: store.bicolorTexture?.texture,
    innerContrastTexture: store.innerContrastTexture?.texture,
    mainTexture: store.mainTexture?.texture,
    cuffsTexture: store.cuffsTexture?.texture,
    buttonColor: store.buttonTexture?.hex_code,
  }));

  if (!bicolorTexture) {
    return null;
  }

  return (
    <group dispose={null} rotation={[-Math.PI / 2, 0, 0]}>
      <mesh geometry={nodes.bicolor.geometry}>
        <meshStandardMaterial map={bicolorTexture} />
      </mesh>

      <mesh geometry={nodes.ärmel.geometry}>
        <meshStandardMaterial map={bicolorTexture} />
      </mesh>

      <mesh geometry={nodes.haubtstoff.geometry}>
        <meshStandardMaterial map={mainTexture} />
      </mesh>

      <mesh geometry={nodes['innen-kontrast'].geometry}>
        <meshStandardMaterial map={innerContrastTexture} />
      </mesh>

      <mesh geometry={nodes['innere-manschetten'].geometry}>
        <meshStandardMaterial map={innerContrastTexture} />
      </mesh>

      <mesh geometry={nodes.knöpfe.geometry}>
        <meshStandardMaterial color={buttonColor} />
      </mesh>

      <mesh geometry={nodes.manschetten.geometry}>
        <meshStandardMaterial map={cuffsTexture} />
      </mesh>
    </group>
  );
};

useGLTF.preload('./build/models/shirt_only.glb');

export default ShirtModel;
