import { animated, useSpring } from '@react-spring/three';
import { Environment, OrbitControls, Preload } from '@react-three/drei';
import { Canvas, invalidate } from '@react-three/fiber';
import React, { Suspense, useState } from 'react';
import { Vector3 } from 'three';

import useZoomedToCollar from '../hooks/useZoomedToCollar';
import { styled } from '../styles/stitches.config';
import ZoomOutIcon from './Icons/ZoomOutIcon';
import ZoomInIcon from './Icons/ZoomInIcon';
import Loading from './Loading';
import Collar from './Models/Collar';
import ShirtModel from './Models/Shirt';
import CopyIcon from '../images/copy.svg';
import { useTranslation } from 'react-i18next';

// https://threejs.org/docs/#examples/en/controls/OrbitControls.target
// https://codesandbox.io/s/three-fiber-zoom-to-object-camera-controls-solution-final-sbgx0?file=/src/App.js:1894-1932

const Container = styled('div', {
  position: 'relative',
  maxHeight: '100vw',
  height: 'calc(100vh - 210px)',

  '@mobile': {
    height: 'calc(100vh - 310px)',
  },
});

const CanvasContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const ZoomButton = styled('button', {
  center: '',
  position: 'absolute',
  right: 8,
  top: 55,
  background: '#fff',
  borderRadius: '50%',
  padding: 10,
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  zIndex: 10,
});

const CopyButton = styled('button', {
  center: '',
  position: 'absolute',
  right: 8,
  top: 120,
  background: '#fff',
  borderRadius: '50%',
  padding: 10,
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  zIndex: 10,
});

const CopyConfirm = styled('div', {
  width: 'max-content',
  center: '',
  position: 'absolute',
  right: 60,
  top: '50%',
  background: '#000',
  color: '#fff',
  borderRadius: '5px',
  padding: '10px 20px',
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  transform: 'translateY(-50%)',
  zIndex: 10,
});

const CopyConfirmText = styled('p', {
  margin: 0,
  fontSize: 14
});

const ShirtScene = () => {
  const { t } = useTranslation();

  const [copiedConfirmation, setCopiedConfirmation] = useState(false);

  const zoomedToCollar = useZoomedToCollar((state) => state.zoomedToCollar ?? false);
  const setZoomedToCollar = useZoomedToCollar((state) => state.setZoomedToCollar);

  const handleZoomedToCollar = () => {setZoomedToCollar(!zoomedToCollar); invalidate();};

  const { position, scale } = useSpring<{ position: Vector3; scale: number }>({
    position: zoomedToCollar ? [0, -210, 0] : [0, -38, 0],
    scale: zoomedToCollar ? 3 : 1,

    onChange: () => invalidate(),
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopiedConfirmation(true)

    setTimeout(() => {
      setCopiedConfirmation(false)
    }, 1000);
  };

  return (
    <Container>
      {
        <ZoomButton onClick={handleZoomedToCollar}>
          {zoomedToCollar ? <ZoomOutIcon /> : <ZoomInIcon />}
        </ZoomButton>
      }

      <CopyButton title={t('share_link_placeholder_label') as string} onClick={copyToClipboard}>
        <img src={CopyIcon} alt="" />
        {
          copiedConfirmation ?
            <CopyConfirm>
              <CopyConfirmText>
                {t('share_link_copied')}
              </CopyConfirmText>
            </CopyConfirm>
          : null
        }
      </CopyButton>

      <CanvasContainer>
        <Canvas shadows camera={{ position: [0, 0, 95], fov: 50 }} frameloop='demand' gl={{ antialias: true }}>
          <Suspense fallback={<Loading />}>
            <Environment files='./build/environment/potsdamer_platz_1k.hdr' />

            <animated.group position={position} scale={scale}>
              <Collar />

              <ShirtModel />
            </animated.group>

            <Preload all />
          </Suspense>
          <directionalLight position={[0, 20, 41]} intensity={0.4} />

          <directionalLight position={[0, 20, 41]} intensity={0.4} />

          <OrbitControls minPolarAngle={1} maxPolarAngle={2} enableZoom={false} enablePan={false} />
        </Canvas>
      </CanvasContainer>
    </Container>
  );
};

export default ShirtScene;
