import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

export class FabricCompositionLabel extends InstanceAbstract implements InstanceInterface {

    /**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }

    /**
     * @type {number}
     */
    private id: number = 0;

    /**
     * @type {string}
     */
    private label_name: string = '';

    /**
     * @type {string}
     */
    private fiber_1: string = '';

    /**
     * @type {number}
     */
    private fiber_1_percentage: number = 0;

    /**
     * @type {string}
     */
    private fiber_2: string = '';

    /**
     * @type {number}
     */
    private fiber_2_percentage: number = 0;

    /**
     * @type {string}
     */
    private fiber_3: string = '';

    /**
     * @type {number}
     */
    private fiber_3_percentage: number = 0;

    /**
     * @type {string}
     */
    private created_at: string = '';

    /**
     * @type {string}
     */
    private updated_at: string = '';

    /**
     * @return {number}
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param  {number} id
     * @return {FabricCompositionLabel}
     */
    public setId(id: number): FabricCompositionLabel {
        this.id = id;
        return this;
    }

    /**
     * @return {string}
     */
    public getLabelName(): string {
        return this.label_name;
    }

    /**
     * @param  {string} label_name
     * @return {FabricCompositionLabel}
     */
    public setLabelName(label_name: string): FabricCompositionLabel {
        this.label_name = label_name;
        return this;
    }

    /**
     * @return {string}
     */
    public getFiber1(): string {
        return this.fiber_1;
    }

    /**
     * @param  {string} fiber_1
     * @return {FabricCompositionLabel}
     */
    public setFiber1(fiber_1: string): FabricCompositionLabel {
        this.fiber_1 = fiber_1;
        return this;
    }

    /**
     * @return {number}
     */
    public getFiber1Percentage(): number {
        return this.fiber_1_percentage;
    }

    /**
     * @param  {number} fiber_1_percentage
     * @return {FabricCompositionLabel}
     */
    public setFiber1Percentage(fiber_1_percentage: number): FabricCompositionLabel {
        this.fiber_1_percentage = fiber_1_percentage;
        return this;
    }

    /**
     * @return {string}
     */
    public getFiber2(): string {
        return this.fiber_2;
    }

    /**
     * @param  {string} fiber_2
     * @return {FabricCompositionLabel}
     */
    public setFiber2(fiber_2: string): FabricCompositionLabel {
        this.fiber_2 = fiber_2;
        return this;
    }

    /**
     * @return {number}
     */
    public getFiber2Percentage(): number {
        return this.fiber_2_percentage;
    }

    /**
     * @param  {number} fiber_2_percentage
     * @return {FabricCompositionLabel}
     */
    public setFiber2Percentage(fiber_2_percentage: number): FabricCompositionLabel {
        this.fiber_2_percentage = fiber_2_percentage;
        return this;
    }

    /**
     * @return {string}
     */
    public getFiber3(): string {
        return this.fiber_3;
    }

    /**
     * @param  {string} fiber_3
     * @return {FabricCompositionLabel}
     */
    public setFiber3(fiber_3: string): FabricCompositionLabel {
        this.fiber_3 = fiber_3;
        return this;
    }

    /**
     * @return {number}
     */
    public getFiber3Percentage(): number {
        return this.fiber_3_percentage;
    }

    /**
     * @param  {number} fiber_3_percentage
     * @return {FabricCompositionLabel}
     */
    public setFiber3Percentage(fiber_3_percentage: number): FabricCompositionLabel {
        this.fiber_3_percentage = fiber_3_percentage;
        return this;
    }

    /**
     * @return {string}
     */
    public getCreatedAt(): string {
        return this.created_at;
    }

    /**
     * @param  {string} created_at
     * @return {FabricCompositionLabel}
     */
    public setCreatedAt(created_at: string): FabricCompositionLabel {
        this.created_at = created_at;
        return this;
    }

    /**
     * @return {string}
     */
    public getUpdatedAt(): string {
        return this.updated_at;
    }

    /**
     * @param  {string} updated_at
     * @return {FabricCompositionLabel}
     */
    public setUpdatedAt(updated_at: string): FabricCompositionLabel {
        this.updated_at = updated_at;
        return this;
    }

    /**
     * Get the Fabric Composition stored on the Fabric as a string
     * @return string
     */
    public generateCompositionLabelString(): string {
        return `${this.getFiber1Percentage()}% [[${this.getFiber1()}]]`
            + ((this.getFiber2Percentage() > 0) 
                ? ` ${this.getFiber2Percentage()}% [[${this.getFiber2()}]]`
                : '')
            + ((this.getFiber3Percentage() > 0)
                ? ` ${this.getFiber3Percentage()}% [[${this.getFiber3()}]]`
                : '');
    }

}