import { InstanceAbstract, InstanceInterface } from './InstanceAbstract';

import { Fabric } from './Fabric';
import { FabricBookCategory } from './FabricBookCategory';
import { Consultant } from './Consultant';

export class FabricBook extends InstanceAbstract implements InstanceInterface {

	/**
     * Constructor
     * @param {object} rawData
     */
    constructor(rawData?: object) {
        super();
        if(rawData) {
            this.importFromObj(rawData, this);
        }
    }


    /**
     * @var number
     */
    private id: number = 0;

    /**
     * @var string
     */
    private title: string = '';

    /**
     * @var string
     */
    private slug: string = '';

    /**
     * @var number
     */
    private consultant_id: number = 0;

    /**
     * @var number
     */
    private likes: number = 0;

    /**
     * @var number
     */
    private order: number = 0;

    /**
     * @var number
     */
    private fabric_book_category_id: number = 0;

    /**
     * @var string
     */
    private visibility: string = 'inactive';

    /**
     * @var string
     */
    private book_cover_image: string = '';

    /**
     * @var string
     */
    private book_cover_image_en: string = '';

    /**
     * @var string
     */
    private book_cover_color: string = '';

    /** ====== Relationships ====== */
    /**
     * These are a list of relationships that will be searched
     * for in any raw data passed to the constructor of a Fabric
     * instance. From there it will try to populate these 
     * relationships with instances of the relationship.
     *
     * See InstanceAbstract for an explanation of the format
     * below
     * @type {string[]}
     */

    public relationships: { [index: string]: string} = {
        'fabric': 'fabrics',
        'consultant': 'consultant',
        'fabricBookCategory': 'category'
    }

    /**
     * Each book has multiple fabrics, this property is an array
     * of Fabric instances belonging to this FabricBook
     * @type {Array<Fabric>}
     */
    private bookFabrics: Array<Fabric> = [] as Array<Fabric>;

    /**
     * The creator and owner of the book
     */ 
    private bookOwner: Consultant = {} as Consultant;

    /**
     * The creator and owner of the book
     */ 
    private bookCategory: FabricBookCategory = {} as FabricBookCategory;

    /**
     * @return number
     */
    public getId(): number {
        return this.id;
    }

    /**
     * @param number
     * @return FabricBook
     */
    public setId(id: number): FabricBook {
        this.id = id;
        return this;
    }

    /**
     * @return string
     */
    public getTitle(): string {
        return this.title;
    }

    /**
     * @param string title
     * @return FabricBook
     */
    public setTitle(title: string): FabricBook {
        this.title = title;
        return this;
    }

    /**
     * @return string
     */
    public getSlug(): string {
        return this.slug;
    }

    /**
     * @param string slug
     * @return FabricBook
     */
    public setSlug(slug: string): FabricBook {
        this.slug = slug;
        return this;
    }

    /**
     * @return number
     */
    public getConsultantId(): number {
        return this.consultant_id;
    }

    /**
     * @param number
     * @return FabricBook
     */
    public setConsultantId(consultant_id: number): FabricBook {
        this.consultant_id = consultant_id;
        return this;
    }

    /**
     * @return number
     */
    public getLikes(): number {
        return this.likes;
    }

    /**
     * @param number
     * @return FabricBook
     */
    public setLikes(likes: number): FabricBook {
        this.likes = likes;
        return this;
    }

    /**
     * @return number
     */
    public getOrder(): number {
        return this.order;
    }

    /**
     * @param number
     * @return FabricBook
     */
    public setOrder(order: number): FabricBook {
        this.order = order;
        return this;
    }

    /**
     * @return number
     */
    public getFabricBookCategoryId(): number {
        return this.fabric_book_category_id;
    }

    /**
     * @param number
     * @return FabricBook
     */
    public setFabricBookCategoryId(fabric_book_category_id: number): FabricBook {
        this.fabric_book_category_id = fabric_book_category_id;
        return this;
    }

    /**
     * @return boolean
     */
    public getVisibility(): string {
        return this.visibility;
    }

    /**
     * @param boolean
     * @return FabricBook
     */
    public setVisibility(visibility: string): FabricBook {
        this.visibility = visibility;
        return this;
    }

    /**
     * @return string
     */
    public getBookCoverImage(): string {
        return this.book_cover_image;
    }

    /**
     * @param string book_cover_image
     * @return FabricBook
     */
    public setBookCoverImage(book_cover_image: string): FabricBook {
        this.book_cover_image = book_cover_image;
        return this;
    }

    /**
     * @return string
     */
     public getBookCoverImageEn(): string {
        return this.book_cover_image_en;
    }

    /**
     * @param string book_cover_image_en
     * @return FabricBook
     */
    public setBookCoverImageEn(book_cover_image_en: string): FabricBook {
        this.book_cover_image_en = book_cover_image_en;
        return this;
    }

    /**
     * @return string
     */
    public getBookCoverColor(): string {
        return this.book_cover_color;
    }

    /**
     * @param string book_cover_color
     * @return FabricBook
     */
    public setBookCoverColor(book_cover_color: string): FabricBook {
        this.book_cover_color = book_cover_color;
        return this;
    }

    /**
     * @return string
     */
    public getFabrics(): Array<Fabric> {
        return this.bookFabrics;
    }

    /**
     * @param fabric
     * @return FabricBook
     */
    public setFabrics(fabric: Fabric): FabricBook {
        this.bookFabrics.push(fabric);
        return this;
    }

    /**
     * @return string
     */
    public getConsultant(): Consultant {
        return this.bookOwner;
    }

    /**
     * @param fabrics owner
     * @return FabricBook
     */
    public setConsultant(bookOwner: Consultant): FabricBook {
        this.bookOwner = bookOwner;
        return this;
    }

    /**
     * @return string
     */
    public getCategory(): FabricBookCategory {
        return this.bookCategory;
    }

    /**
     * @param fabrics owner
     * @return FabricBookCategory
     */
    public setCategory(bookCategory: FabricBookCategory): FabricBook {
        this.bookCategory = bookCategory;
        return this;
    }
}