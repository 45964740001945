import { useViewportSize } from '@mantine/hooks';
import React, { useEffect } from 'react';
import useTextures from '../../hooks/useTextures';

import { styled } from '../../styles/stitches.config';
import ScrollArea from '../ScrollArea';
import OpenButtonConfiguratorButton from './Buttons/OpenButtonsConfiguratorButton';
import OpenCollarConfiguratorButton from './Buttons/OpenCollarConfiguratorButton';
import OpenShirtConfiguratorButton from './Buttons/OpenShirtConfiguratorButton';
import TextureSelectModal from './TextureSelectModal';

const Container = styled('div', {
  center: '',

  height: 120,
  margin: 12,

  '@mobile': {
    flexDirection: 'column',
    justifyContent: 'normal',
    alignItems: 'normal',
  },
});

const MobileShirtSelector = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '620px',
});

const ScrollContainer = styled('div', {
  height: 124,
});

const Buttons = () => {
  return (
    <>
      <OpenShirtConfiguratorButton tab='all' main='#999' bicolor='#999' collar='#999' contrast='#999' />
      <OpenShirtConfiguratorButton tab='main' main='#999' />
      <OpenShirtConfiguratorButton tab='bicolor' bicolor='#999' />
      <OpenShirtConfiguratorButton tab='cuffsAndCollar' collar='#999' />
      <OpenShirtConfiguratorButton tab='innerContrast' contrast='#999' />

      <OpenCollarConfiguratorButton />
      <OpenButtonConfiguratorButton />
    </>
  );
};

const ShirtConfigurator = () => {
  const { width } = useViewportSize();

  const getFabrics = useTextures(state => state.getFabrics)

  useEffect(() => {
    getFabrics()
  }, [getFabrics])
  

  return (
    <>
      <TextureSelectModal />

      <Container>
        {width <= 575.98 ? (
          <ScrollContainer>
            <ScrollArea height={116}>
              <MobileShirtSelector>
                <Buttons />
              </MobileShirtSelector>
            </ScrollArea>
          </ScrollContainer>
        ) : (
          <Buttons />
        )}
      </Container>
    </>
  );
};

export default ShirtConfigurator;
