import axios, { AxiosResponse, AxiosPromise } from 'axios';
import { serialize } from 'object-to-formdata';

import { HttpMethod, ApiResponse, ServiceConfig} from './types';
import { GenericResult, ResultType, CollectionResultType, AccessToken } from './utils';
import { Builder, FabricBuilder, ThreadBuilder, ButtonBuilder, ShirtGalleryBuilder } from './builder';
import { CachingService } from './cache'

export default class Base {

    /**
     * @type {string}
     */
    protected accessToken: string = '';

    /**
     * @type {string}
     */
    protected apiBaseUrl: string = '';

    /**
     * @type {string}
     */
    protected shopBaseUrl: string;

    /**
     * @type {Builder}
     */
    private builder: Builder;

    /**
     * @type {CachingService}
     */
    private cache = new CachingService();

    /**
     * Constructor
     * @param {string} config
     */
    constructor(config: ServiceConfig) {
        this.shopBaseUrl = config.shopBaseUrl;
        this.apiBaseUrl = config.apiBaseUrl;
        this.builder      = new Builder(this);
    }

    /**
     * Update the current access token used by the client
     * @param {AccessToken} token
     */ 
    public updateAccessToken(token: AccessToken) {
        this.accessToken = token.access_token;
    }

    /**
     * Update the current access token used by the client
     * Called instead of the method above if you wish to
     * set the token directly from a pre-obtained
     * access token as a string
     * @param {AccessToken} token
     */ 
    public updateAccessTokenDirectly(token: string) {
        this.accessToken = token;
    }

    /**
     * Get an instance of the Builder
     * @param {string} type
     * @return {Builder}
     */
    public getBuilder(type?: string): Builder {
        if(!type) {
            return this.builder;
        }

        switch(type) {
            case 'FabricBuilder':
                return new FabricBuilder(this);

            case 'ThreadBuilder':
                return new ThreadBuilder(this);

            case 'ButtonBuilder':
                return new ButtonBuilder(this);

            case 'ShirtGalleryBuilder':
                return new ShirtGalleryBuilder(this);

            default:
               return this.builder;
        }
    }

    /**
     * Generic make HTTP request method
     * @param {HttpMethod} method
     * @param {string} endpoint
     * @param {object|FormData}data
     * @return Promise<GenericResult|undefined>
     */
    public async request<T>(method: HttpMethod, endpoint: string, data: object | FormData, isShopRequest?: boolean, shouldCache?: boolean): Promise<GenericResult|undefined> {

        let requestKey = `${method}_${endpoint}`;
        let params = JSON.stringify(data);


        if(shouldCache) {
            if (this.cache.checkCacheEntryExists(requestKey, params)) {
                return new Promise(resolve => resolve(this.cache.getEntry(requestKey, params)));
            }
        }

        /**
         * Build the request headers
         */ 
        let headers = {
            'Authorization': 'Bearer ' + this.accessToken,
            'Accept': 'application/json',
            'Content-type' : 'application/octet-stream'
        }

        if(method == "post") {
            headers['Content-type'] = 'multipart/form-data';
        }

        if(method == "put") {
            headers['Content-type'] = 'application/x-www-form-urlencoded';
        }

        if(method == "delete") {
            headers['Content-type'] = 'application/json';
        }

        /**
         * Set the correct url
         */ 
        let url = (isShopRequest ? this.shopBaseUrl : this.apiBaseUrl);

        /**
         * Remove the trailing /
         */ 
        url = url.replace(/\/$/, '') + '/' + endpoint;

        /**
         * Build the request options
         */ 
        let requestOptions = {
            headers: headers, 
            method: method,
            url: url,
            params: {},                       
            data: {},
            maxBodyLength: Infinity,
            maxContentLength: Infinity                     
        };

        /**
         * 
         */ 
        if(method == "get" || method == "put") {
            requestOptions.params = data;
        } else if(method == "post") {
            if(data instanceof FormData) {
                requestOptions.data = data;
            } else {
                /**
                 * Convert the JS Object to FormData, if not the data will be sent as JSON in the request body
                 */ 
                requestOptions.data = serialize(data);
            }            
        } else {
            requestOptions.data = data;
        }

        /**
         * Send the request and return response
         */ 
        
        try {
            const response = await axios.request<ResultType|CollectionResultType>(requestOptions);
            if(response.headers['content-type'] !== "application/json") {
                throw new Error(`Response not in JSON format`);
            }

            const res = new GenericResult(response.data);
            if(shouldCache) {
                this.cache.setEntry(requestKey, res, params);
            }
            return res;

        } catch(error) {
            // @todo something
        }
    }

}
