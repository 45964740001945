type CacheResult = any;

export class CachingService {

    /**
     * Holds a singleton instance of this class
     * @type {CachingService}
     */
    private static instance: CachingService;

    /**
     * Array of cache entries
     * @type {string}
     */
    private cacheEntries: { key: string, params?: string, result: CacheResult }[] = [];

    /**
     * Class constructor, creates a singleton instance
     * of this class
     */
    constructor() {
        if (CachingService.instance) {
            return CachingService.instance;
        }
        CachingService.instance = this;
        return this;
    }

    /**
     * Set an entry into the cache
     * @param  {string} key
     * @param  {string} params
     * @param  {any} result
     * @return {CachingService}
     */
    public setEntry(key: string, result:any, params?: string):CachingService {
        if(!this.checkCacheEntryExists(key, params)) {
            this.cacheEntries.push({
                key,
                result,
                params
            })
        }
        return this;
    }

    /**
     * Get a cache entry
     * @param  {string} key
     * @param  {string} params
     * @return {CacheResult}
     */
    public getEntry(key: string, params?: string): CacheResult | null {
        const entry = this.cacheEntries.find(item => {
            if(params) {
                return (item.key === key && item.params === params);
            } else {
                return (item.key === key);
            }
        })

        return (entry) ? entry.result : null;
    }

    /**
     * Checks if an entry exists in the cache
     * @param  {string} key
     * @param  {string} params
     * @return {boolean}
     */
    public checkCacheEntryExists(key: string, params?: string): boolean {
        return (this.getEntry(key, params));
    }

}