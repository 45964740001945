import { AxiosResponse } from 'axios';

export function applyMixins(derivedCtor: any, constructors: any[]) {
    constructors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            Object.defineProperty(
                derivedCtor.prototype,
                name,
                Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
                Object.create(null)
            );
        });
    });
}

export type ResultType = {
    status: string|boolean,
    data: []|{}
}

export type CollectionResultType = {
    ststus: string|boolean,
    data: {
        collection: Array<any>,
        pagination: { [index: string]: number}
    }
}

export type ExtendedCollectionResultType = {
    ststus: string|boolean,
    data: {
        collection: Array<any>,
        curated_books: Array<any>,
        my_books: Array<any>,
        pagination: { [index: string]: number}
    }
}

export class GenericResult {

    private result: ResultType|CollectionResultType;

    constructor(data: ResultType|CollectionResultType) {
        this.result = data;
    }

    public getResults(): ResultType|CollectionResultType {
        return this.result;
    }

}

export type ConsultantSystemAuthResult = {
    user_info: string,
    is_client: boolean,
};

export type AccessToken = {
    access_token: string,
    token_type: string,
    expires_in: number,
    api_base_url: string
};