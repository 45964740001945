// import { ThreadTrait, ButtonTrait, ShopDataTrait, ShirtGalleryTrait } from './traits';
import { ThreadTrait, ButtonTrait, FabricTrait, FabricBookTrait, FabricBookCategoryTrait, ShirtGalleryTrait, ShopDataTrait, AuthenticationTrait, AccountTrait } from './traits';
import { applyMixins } from './utils';
import { ServiceConfig } from './types';
import Base from './base';
import { Fabric } from './instance/Fabric';
import {createIntl, createIntlCache, IntlCache, IntlShape } from '@formatjs/intl'
import { deMessages, enMessages } from './translations';

class ClientBase extends Base {

	/**
	 * @type {IntlCache}
	 */
	private translateCache: IntlCache;

	/**
	 * @type {IntlShape}
	 */
	private translator: IntlShape;

	/**
	 * @param {Config} config
	 * @param {string = 'de'} lang
	 */
	constructor(config: ServiceConfig, lang: string = 'de') {
		super(config);
		this.translateCache = createIntlCache();

		let messages = {};
		switch(lang) {
			case 'en':
				Object.assign(messages, enMessages.translations);
				break;
			case 'de':
			default:
				Object.assign(messages, deMessages.translations);
				break;
		}

		this.translator = createIntl({
			locale: lang,
			defaultLocale: 'de',
			messages: messages
		}, this.translateCache);
	}

	/**
     * Use this function to do a literal translation based on a
     * translation key. You can provide as many parameters as 
     * needed.
	 * @param {string} key
	 * @param {any[]}  ...params
	 */
	public t(key: string, ...params: any[]) {
		params = (params.length > 0) ? params.shift() : {};
		return this.translator.formatMessage({ id: key }, params);
	}

	/**
     * Use this function to translate a string with multiple keys
     * in it (e.g. "50% [[Cotton]] 25% [[Polyester]]"). It will split
     * the translation keys and translate them individually. 
	 * @param {string} string
	 * @param {any[]}  ...params
	 */
	public tt(string: string, ...params: any[]) {
		if(string) {
			const translationKeys = string.match(/\[\[([^\[]*)]/g);
			params = (params.length > 0) ? params.shift() : {};
			const translationArray = translationKeys as string[];
			if(translationArray) {
				translationArray.map(key => {
					let translatedKey = this.translator.formatMessage({ id: key }, params);
					string = string.replace(key, translatedKey);
				});
			}
		}		
		
		return string;
	}

	/**
	 * This method allow to dynamically add more translation messages without embedding it to the library files
	 */ 
	public addTranslationMessages(messages: Object){
		if(messages.hasOwnProperty(this.translator.locale)) {
			this.translator.messages = Object.assign(this.translator.messages, (messages as any)[this.translator.locale]);
		} else {
			console.warn("The new translations don't have the current language ["+this.translator.locale+"]");
		}
	}

	/**
	 * Method to convert a fabric note date into a beginning, middle or end of certain month and have it translated to the selected language
	 */ 
	public  getFabricNoteTranslation(fabric: Fabric): string | undefined {
		const note = fabric.getNote().replace(/\-/g, "/");
		let formattedDate = new Date(note);

		if (!formattedDate.getTime()) {
			const [day, month, year] = note.split("/");
			formattedDate = new Date([month, day, year].join("/"));
		}

		if (!formattedDate.getTime()) { return note }

		const day = formattedDate.getDate()
		const month = formattedDate.getMonth()
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
		if (day <= 10) {
				return this.t("beginningOf") + this.t(months[month])
		} else if (day > 10 && day <= 20) {
				return this.t("middleOf") + this.t(months[month])
		} else if (day > 20) {
				return this.t("endOf") + this.t(months[month])
		}
	}

	/**
	 * A function that is used to determine the current availability of a fabric and display the correct note accordingly
	 */ 
	 public  checkFabricAvailability(fabric: Fabric): string | undefined {
		if (fabric.getFuture() === 'keep' && (fabric.getStatus() === "available" || fabric.getStatus() === "soon_sold_out_coming_back")) {
			return this.t("availableNote");
		} else if (fabric.getFuture() === 'keep' && fabric.getStatus() === "sold_out_coming_back") {
				return this.t("comingBackNote");
		} else if (fabric.getFuture() === 'out' && fabric.getStatus() === "sold_out_not_coming_back") {
				return this.t("notComingBackNote");
		} else {
				return "available";
		}
	}

}

interface ClientBase extends ThreadTrait, ButtonTrait, FabricTrait, FabricBookTrait, FabricBookCategoryTrait, 
ShirtGalleryTrait, ShopDataTrait, AuthenticationTrait, AccountTrait {}
applyMixins(ClientBase, [ThreadTrait, ButtonTrait, FabricTrait, FabricBookTrait, FabricBookCategoryTrait, 
	ShirtGalleryTrait, ShopDataTrait, AuthenticationTrait, AccountTrait]);
export default ClientBase