import Base from '../base'
import { Builder } from '../builder';
import { ResultType } from '../utils';
import { ShopData } from '../shop';
import { Fabric, ButtonColor, ThreadColor } from '../instance';
import Dictionary from '../collection';

export class ShopDataTrait extends Base {

	/**
	 * This property tell if the shop raw data was already fetched.
	 */ 
	protected shopDataIsFetched: boolean = false;

	/**
	 * This property store the shop raw data, before they become available to the getter methods
	 */ 
	protected shopData: ShopData = {} as ShopData;

	/**
	 * Get the list of fabrics
	 * @return {Promise<Dictionary<Fabric>>}
	 */ 
	async getShopFabrics(): Promise<Dictionary<Fabric>> {

		if(!this.shopDataIsFetched) {
			await this.fetchShopData();
		}

		const collection = new Dictionary<Fabric>();
		var count = 1;
		this.shopData.fabrics.map(fabric => {
			collection.addItem(count.toString(), <Fabric>Fabric.createFromRaw(fabric, 'Fabric'));
            count++;
		});

		return collection;
	}

	/**
	 * Get the list of thread colors
	 * @return {Promise<Dictionary<ThreadColor>>}
	 */ 
	async getShopThreadColors(): Promise<Dictionary<ThreadColor>> {

		if(!this.shopDataIsFetched) {
			await this.fetchShopData();
		}

		const collection = new Dictionary<ThreadColor>();
		var count = 1;
		this.shopData.threads.map(thread => {
			collection.addItem(count.toString(), <ThreadColor>ThreadColor.createFromRaw(thread, 'ThreadColor'));
            count++;
		});

		return collection;
	}

	/**
	 * Get the list of button colors
	 * @return {Promise<Dictionary<ButtonColor>>}
	 */ 
	async getShopButtonColors(): Promise<Dictionary<ButtonColor>> {

		if(!this.shopDataIsFetched) {
			await this.fetchShopData();
		}

		const collection = new Dictionary<ButtonColor>();
		var count = 1;
		this.shopData.buttons.map(button => {
			collection.addItem(count.toString(), <ButtonColor>ButtonColor.createFromRaw(button, 'ButtonColor'));
            count++;
		});

		return collection;
	}

	/**
	 * Fetch the raw data of fabrics, thread and buttons colors.
	 * This data are fetched from a unique endpoint and the above methods will seperate them
	 */ 
	async fetchShopData(): Promise<void> {
		let builder = <Builder>this.getBuilder('Builder');
		/**
		 * The result of the request can be cached.
		 */ 
		const results = await builder.execute("get", 'shirt_orders/shop', {}, false, true);

		if(!results) {
			throw new Error('Error while fetching shop data');
		}

		const result = <ResultType>results.getResults();

		this.shopData = <ShopData>result.data;
		this.shopDataIsFetched = true;
		
	}
}