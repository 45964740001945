import { useState } from "react"
import { useTranslation } from "react-i18next"
import useTexture from "../hooks/useTextures"
import { styled } from "../styles/stitches.config";

const LoginModal = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [userType, setUserType] = useState("consultant")
  const toggleLoginModal = useTexture(state => state.toggleLoginModal);
  const handleLogin = useTexture(state => state.handleLogin)
  const { loginErrorText, showLoginErrorMsg, logginIn } = useTexture(state => state)

  const onBlurHandler = (event: React.MouseEvent<HTMLElement>) => {
    if(event.target === event.currentTarget) {
      toggleLoginModal()
    } 
  }

  const CloseButton = styled('img', {
    height: '13px',
    width: '13px',
    position: 'absolute',
    right: '14px',
    top: '14px',
    cursor: 'pointer'
  })

  const Container = styled('div', {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    color: '#838283'
  })

  const Button = styled('button', {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    borderRadius: '10px',
    height: '30px',
    fontSize: '14px',
    color: '#838283',
    marginLeft: '10px',
    border: '1px solid #838282',
    '&.active': {
      backgroundColor: '#0673e9',
      color: 'white',
      border: 'none'
    },
  })

  const LoginTypeSelector = (
    <Container>
      <div>{t('login.login_as')}</div>
      <Button className={userType == 'consultant' ? 'active' : ''} onClick={() => setUserType('consultant')}>{t('login.consultant')}</Button>
      <Button className={userType == 'client' ? 'active' : ''}  onClick={() => setUserType('client')}>{t('login.client')}</Button>
    </Container>
  )

  return (
    <div className="login-modal">
      <div onClick={onBlurHandler} className="overlay"></div>
      <div className="modal">
        <CloseButton onClick={toggleLoginModal} src="./build/icons/close_icon.svg" alt="icon"/>
        <div className="head">
          <h3 className="title">{t('login.login_title')}</h3>
          { LoginTypeSelector }
        </div>
        <div className="body">
          <input type="text" placeholder={t('login.email') as string} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder={t('login.password') as string} onChange={(e) => setPassword(e.target.value)} />
        </div>
        {showLoginErrorMsg ? <p className="error">{t('validation_message.' + loginErrorText)}</p> : null}

        <div className="bottom-container">
          <div className="bottom">
            <div className='btn-cancel' onClick={toggleLoginModal}>
              <p>{t('login.cancel_button_label')}</p>
            </div>
          </div>
          <div className="bottom">
            <div className={"btn-submit " + (logginIn ? "disabled" : "")} onClick={() => handleLogin(email, password, userType)}>
              <p>{t('login.submit_button_label')}</p>
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default LoginModal
