import { Html, useProgress } from '@react-three/drei';
import React from 'react';

import { keyframes, styled } from '../styles/stitches.config';

const Container = styled('div', {
  center: '',

  flexDirection: 'column',
});

const rotateAnimation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const Loader = styled('div', {
  width: 48,
  height: 48,
  border: '5px solid #4b4b4b',
  borderBottomColor: 'transparent',
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: `${rotateAnimation} 1s linear infinite`,
});

const Loading = () => {
  const { active, progress } = useProgress((state) => ({ active: state.active, progress: state.progress }));

  if (active) {
    return (
      <Html center>
        <Container>
          <Loader />
          <span>{Math.round(progress)}%</span>
        </Container>
      </Html>
    );
  }

  return null;
};

export default Loading;
